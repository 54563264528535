.accommodation-teaser {
    display:flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0 calc(20rem/16) calc(40rem/16) 0 rgba(35,59,72,0.1);
}
.accommodation-teaser__title {
    font-family: var(--font-default-bold);
    margin-top: calc(5rem/16);
    margin-bottom: calc(0rem/16);
    font-size: calc(26rem/16);
    text-align:center;
}
.accommodation-teaser__ribbon {
    margin: calc(10rem/16) auto;
}
.accommodation-teaser__location {
    font-size: calc(16rem/16);
    margin-bottom: calc(10rem/16);
    text-align: center;
}
.accommodation-teaser__date {
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    color: var(--color-primary);
}
.accommodation-teaser__body {
    padding: calc(30rem/16) calc(30rem/16) calc(30rem/16);
    display:flex;
    flex-direction: column;
    flex:1;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
.accommodation-teaser__category {
    text-align: center;
    padding: calc(10rem/16) 0;
    position: relative;
    margin-top: calc(10rem/16);
}
.accommodation-teaser__category:before {
    content:'';
    width: calc(50rem/16);
    height: calc(1rem/16);
    background-color: var(--color-primary);
    margin: 0 auto;
    display: block;
    top: 0;
    position: absolute;
    left: 0;
    right: 0;
}
.accommodation-teaser__category:after {
    content:'';
    width: calc(50rem/16);
    height: calc(1rem/16);
    background-color: var(--color-primary);
    margin: 0 auto;
    display: block;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
}
.accommodation-teaser__badge {
    position: absolute;
    top:0;
    right:0;
    background-color: var(--color-primary);
    color:#fff;
    padding: calc(10rem/16);
}
.accommodation-teaser__price {
    font-family: var(--font-default-bold);
    color: var(--color-primary);
    font-size: calc(20rem/16);
}
.accommodation-teaser__img {
    transition: transform 0.3s ease;
}
.accommodation-teaser__checkbox {
    display: flex;
    justify-content: center;
    align-items:center;
    line-height: 1;
}
.accommodation-teaser__rating-logo {
    width: calc(100rem/16);
    margin: 0 auto;
    margin-top: calc(5rem/16);
}
.accommodation-teaser__footer-btn {
    width: 100%;
    font-family: var(--font-default-bold);
    background-color: #fff;
    color: var(--color-primary);
    height: 100%;
    border: calc(1rem/16) solid var(--color-primary);
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
}
.accommodation-teaser__footer {
    display: flex;
    margin-top: auto;
}
@media screen and (min-width: 768px) {
    .accommodation-teaser__footer-btn:hover {
        background-color: var(--color-primary);
        color:#fff;
    }
    .accommodation-teaser:hover .accommodation-teaser__img{
        transform: scale(1.1);
    }
}
.accommodation-teaser__footer-btn-icon {
    background-color: var(--color-primary);
    color: #fff;
    height: calc(50rem/16);
    width: calc(50rem/16);
    display: inline-flex;
    justify-content: center;
    align-items:center;
    border-right: calc(1rem/16) solid #fff;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    text-rendering: auto;
    transform: translate(0);
}
.accommodation-teaser__footer-btn-icon:before {
    content: var(--icon-plus);
    transition: transform 0.2s ease;
}
.accommodation-teaser__footer-btn.active .accommodation-teaser__footer-btn-icon:before{
    transform: rotate(45deg);
}
.accommodation-teaser__footer-btn.active {
    background-color: var(--color-primary);
    color: #fff;
    border-color: #fff;
}
.accommodation-teaser__footer-btn.active .accommodation-teaser__footer-btn-icon {
    color: #fff;
}
.accommodation-teaser__footer-text {
    display:inline-flex;
    padding: calc(10rem/16) calc(10rem/16) calc(10rem/16) calc(20rem/16);
    line-height:1;
}