html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.page-wrapper--full-height {
    min-height: calc(100vh - 250px);

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        min-height: calc(100vh - 191px);
    }
}

html.is-scrolling-disabled,
html.is-scrolling-disabled body {
    overflow: hidden;

}
html.is-scrolling-disabled body {
    position: relative;

    @media screen and (max-width: 767px) {
        position: fixed;
        left: 0;
        right: 0;
    }
}
.main-content__body {
    overflow: hidden;
}
.main-content__body--lp {
    margin-top: -10%;
    z-index: 2;
    position:relative;
}