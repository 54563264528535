.img-teaser__embed--portrait:before {
    padding-top: calc( 420 / 330 * 100%);
}
.img-teaser__embed--landscape:before {
    padding-top: calc( 335 / 700 * 100%);
    @media screen and (max-width: 767px) {
        padding-top: 100%;
    }
}
.img-teaser__embed--square:before {
    padding-top: calc( 335 / 335 * 100%);
}
.img-icon-teaser {
    position: relative;
    cursor:pointer;
}
.img-icon-teaser:before {
    background: linear-gradient(180deg, rgba(47,66,80,0) 0%, rgba(47, 66, 80, 0.94) 100%);
    content:'';
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 1;
    transition: opacity 0.7s ease;
}
.img-icon-teaser__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    color:#fff;
    padding: calc(20rem/16) calc(23rem/16);
    text-align:center;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16) calc(10rem/16);
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        padding: calc(10rem/16);
    }
}
.img-icon-teaser__title {
    font-size: calc(26rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(28/26);
    text-shadow: 0 calc(2rem/16) calc(30rem/16) rgba(0,0,0,0.3);
    margin-bottom:0;
    text-transform:uppercase;
    position: relative;
    transition: transform 0.4s ease-out;
    
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
    }
}
.img-icon-teaser__content{
    line-height: calc(23/18);
    transition: transform 0.4s ease-out;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}
.img-icon-teaser__icon {
    font-size: calc(80rem/16);
    transition: transform 0.4s ease-out;

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        font-size: calc(60rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(30rem/16);
    }
}
.img-icon-teaser__icon--has-circle {
    position: relative;
    font-size: calc(60rem/16);
    margin-bottom: 0;
    transition: transform 0.4s ease-out;
    height: calc(120rem/16);

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        font-size: calc(40rem/16);
        height: calc(80rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(30rem/16);
        height: calc(60rem/16);
    }
}
.img-icon-teaser__button {
    margin-top: calc(20rem/16);

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        margin-top: calc(5rem/16);
    }

    @media screen and (max-width: 767px) {
        display: none;
    }
}
.img-icon-teaser__button .btn {
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        padding: calc(10rem/16);
    }
}
.img-icon-teaser--text-left .img-icon-teaser__body{
    text-align: left;
}
.img-icon-teaser--text-left .img-icon-teaser__title:after {
    margin: 0;
}
.img-icon-teaser--text-smaller .img-icon-teaser__title {
    font-size: calc(20rem / 16);
    line-height: calc(25 / 20);
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
    }
}
.img-icon-teaser--text-smaller .img-icon-teaser__content {
    max-width: calc(300rem/16);
    text-transform: unset;
}

.img-icon-teaser__percentage-circle  {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-15%);
    display:block;
    width: calc(120rem/16);

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: calc(80rem/16);
    }
    @media screen and (max-width: 767px) {
        width: calc(60rem/16);
    }
}
.img-icon-teaser__percentage-circle .circle {
    stroke: rgba(255, 255, 255, 0.4);
    fill: none;
    stroke-width: calc(2rem/16);
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
}
.img-icon-teaser__percentage-circle .circle-active {
    stroke: #fff;
    fill: none;
    stroke-width: calc(2rem/16);
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}

@media screen and (min-width: 1200px) {
    .img-teaser__media {
        transition: transform 0.5s ease-in-out;
    }
    .img-icon-teaser:hover .img-teaser__media {
        transform: scale(1.05);
    }
    .img-icon-teaser:hover .img-icon-teaser__icon {
        transform: translateY(calc(-20rem/16));
    }
    .img-icon-teaser:hover .img-icon-teaser__icon--has-circle {
        transform: translateY(calc(-20rem/16));
    }
    .img-icon-teaser:hover .img-icon-teaser__title {
        transform: translateY(calc(-15rem/16));
    }
    .img-icon-teaser:hover:before {
        opacity:0.6;
    }
    .img-icon-teaser:hover .img-icon-teaser__content {
        transform: translateY(calc(-10rem/16));
    }
}