.partner-logo-slider__item {
    padding: calc(30rem/16) 0;
}

@media screen and (max-width: 767px) {
    .partner-logo-slider {
        padding-bottom: calc(50rem/16);
    }

    .partner-logo-slider__list {
        padding-bottom: calc(40rem/16);
    }
}