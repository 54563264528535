.snow-report-item__title {
    font-size: calc(30rem/16);
    font-family: var(--font-default-bold);
    line-height: 1;
    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
    }
}
.snow-report-item__title:after {
    content: '';
    background-color: var(--color-primary-light);
    height: 1px;
    width: calc(161rem/16);
    display: block;
    margin-top: calc(31rem/16);
    margin-bottom: calc(35rem/16);

    @media screen and (max-width: 767px) {
        margin-top: calc(16rem/16);
    }
}
.snow-report-item {
    font-size: calc(16rem/16);
}