.container.container {
    max-width: calc(1460rem/16);
    width: 100%;
}
.container--no-padding-xs {
    @media screen and (max-width: 767px) {
        padding-left:0;
        padding-right:0;
    }
}
.container.container-narrow {
    max-width: calc(950rem/16);
    width: 100%;
}