.img-cropper__btn {
    padding: calc(5rem/16);
    border-radius:0;
    width: calc(48.7rem/16);
    height: calc(48.7rem/16);
    display: inline-flex;
    justify-content: center;
    align-items:center;
    font-size: calc(22rem/16);
}
.img-cropper__button-group {
    margin-top: calc(5rem/16);
}
.img-cropper__cropper-wrapper.img-cropper-dropzone--over .cropper-modal{
    background-color: var(--color-primary);
}
.img-cropper__container {
    width: calc(210rem/16);
}
.img-cropper__container.has-error {
    color: var(--color-danger);
}
.img-cropper__img {
    max-width: 100%;
    display: block;
}
.img-cropper__cropper-wrapper {
    width: calc(210rem/16);
    height: calc(280rem/16);
}
.img-cropper__input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}
.img-cropper__result {
    width: calc(210rem/16);
    height: calc(280rem/16);
    background-color: var(--color-light-grey);
    border:2px solid var(--color-grey);
}