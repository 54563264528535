.ad-teaser__wrapper iframe {
    height: calc(620rem/16);
}
.ad-teaser__wrapper > div {
    height: auto !important;
    width: auto !important;
}

.ad-zone__wrapper {
    margin-top: calc(80rem/16);
}
.ad-teaser__wrapper iframe {
    height: calc(620rem/16);
    border:0;
    @media screen and (min-width: 1460px) {
        width: calc((1460px - 30px) / 4 );
        padding-left: calc(5rem/16);
        padding-right: calc(5rem/16);
    }
    @media screen and (min-width: 768px) and (max-width: 1459px) {
        width: calc(25vw - 15px);
        padding-left: calc(5rem/16);
        padding-right: calc(5rem/16);
    }
    @media screen and (max-width: 768px) {
        width:100%;
    }
}