.feature-slider__wrapper {
    position: relative;
}
.feature-slider__item:before {
    content:'';
    position: absolute;
    height: 60%;
    bottom:0;
    width: 100%;
    background: linear-gradient(180deg, rgba(47,66,80,0) 0%, #2F4250 100%);
    opacity:0.6;
}
.feature-slider__item:after {
    content:'';
    position: absolute;
    height: 100%;
    left:0;
    width: 60%;
    background: linear-gradient(270deg, rgba(47,66,80,0) 0%, #2F4250 100%);
    opacity:0.6;
    top:0;
    z-index: -1;
}
.feature-slider__item {
    height: calc(800rem/16);
    z-index: 2;
    position: relative;
    background-size:cover;
    background-position: center;
    @media screen and (max-width: 767px) {
        height: calc(375rem/16);
    }
    @media screen and (min-width: 768px) and (max-width: 1400px) {
        height: calc(600rem/16);
    }
}
.feature-slider__item-logo-wrapper {
    max-width: calc(170rem/16);
    margin-bottom: calc(20rem/16);
    @media screen and (max-width: 767px) {
        max-width: calc(100rem/16);
        margin-bottom:0;
    }
}
.feature-slider__item-content {
    display: flex;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-end;
    padding-bottom: calc(50rem/16);
    color:#fff;
    @media screen and (max-width: 767px) {
        padding-bottom: calc(20rem/16);
    }
}
.feature-slider--has-thumbs .feature-slider__item-content {
    padding-bottom: calc(200rem/16);
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        padding-bottom: calc(100rem/16);
    }
    @media screen and (max-width: 767px) {
        padding-bottom: calc(80rem/16);
    }
}
.feature-slider__headline {
    font-size: calc(40rem/16);
    text-shadow: 0 calc(2rem/16) calc(30rem/16) 0 rgba(0,0,0,0.5);
    line-height: calc(50/40);
    font-family: var(--font-default-bold);
    margin-bottom: calc(18rem/16);
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
    }
}
.feature-slider__description {
    margin-bottom: calc(18rem/16);
    @media screen and (max-width: 767px) {
        display:none;
    }
}
.feature-slider__nav-wrapper {
    height: calc(80rem/16);
    position: absolute;
    bottom:0;
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 2;
}
.feature-slider__nav {
    border-top: calc(1rem/16) solid #fff;
    height:100%;
    color:#fff;
}
.feature-slider__nav-item {
    width: auto;
    height: calc(80rem/16);
}
.slick-slide .feature-slider__nav-item-content:before {
    content:'';
    position: absolute;
    height: 0;
    background-color:#fff;
    top:0;
    width: 100%;
    left:0;
    opacity:0;
    transition: opacity 0.3s ease, height 0.3s ease-in-out;
}
.slick-slide.slick-current .feature-slider__nav-item-content:before {
    opacity:1;
    height: calc(8rem/16);

}
.feature-slider__nav-item-content {
    position: relative;
    display:flex;
    align-items:center;
    text-transform:uppercase;
    height:100%;
    margin-left: calc(30rem/16);
    margin-right: calc(30rem/16);
    cursor:pointer;
    transition: transform 0.2s ease-out;
}
.feature-slider__nav-item-content:hover {
    transform: translateY(calc(-3rem/16));
}
.feature-slider__nav .slick-slide:first-of-type .feature-slider__nav-item-content{
    margin-left:0;
}
.feature-slider__arrow-wrapper {
    position: absolute;
    bottom: calc(50rem/16);
    width: 100%;
    left:0;
    right: 0;
    pointer-events:none;
    @media screen and (max-width: 767px) {
        bottom: 1.25rem;
    }
}
.feature-slider--has-thumbs .feature-slider__arrow-wrapper {
    bottom: calc(140rem/16);
    @media screen and (max-width: 767px) {
        bottom: 1.25rem;
    }
}
.feature-slider__arrow {
    position: relative;
}
.feature-slider__arrow .icon {
    position: absolute;
    top: 50%;
    left: 50%;
}
.feature-slider__arrow.arrow-next .icon {
    transform: translate(-50%, -50%) rotate(-90deg);
}
.feature-slider__arrow.arrow-prev .icon {
    transform: translate(-50%, -50%) rotate(90deg);
}

@media screen and (min-width: 768px) {
    .feature-slider__animated-item {
        opacity:0;
        transform: translateX(calc(200rem/16));
    }
    .feature-slider__animated-item:nth-child(1) {
        transition: opacity 0.6s ease-out 0.5s, transform 0.8s cubic-bezier(0.4, 0, 0.3, 1) 0.5s;
    }
    .feature-slider__animated-item:nth-child(2) {
        transition: opacity 0.6s ease-out 0.6s, transform 0.8s cubic-bezier(0.4, 0, 0.3, 1) 0.6s;
    }
    .feature-slider__animated-item:nth-child(3) {
        transition: opacity 0.6s ease-out 0.7s, transform 0.8s cubic-bezier(0.4, 0, 0.3, 1) 0.7s;
    }
    .feature-slider__animated-item:nth-child(4) {
        transition: opacity 0.6s ease-out 0.8s, transform 0.8s cubic-bezier(0.4, 0, 0.3, 1) 0.8s;
    }
    .slick-current.slick-active .feature-slider__animated-item {
        transform: translateX(0rem);
        opacity:1;
    }

}
