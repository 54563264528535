.main-nav__sub-nav {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    visibility: hidden;
    pointer-events: none;
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    z-index: 100;
    @media screen and (max-width: 991px) and (min-width: 768px) {
        position: absolute;
        transform: translateX(100%);
        transition: transform 120ms, visibility .001ms 120ms;
        font-size: calc(20rem / 16);
        padding: 6.25rem calc(47rem/16) calc(70rem/16);
        pointer-events:none;
    }
    @media screen and (max-width: 768px) {
        padding: calc(50rem/16) calc(20rem/16);
        position: absolute;
        transform: translateX(100%);
        transition: transform 120ms, visibility .001ms 120ms;
        font-size: calc(16rem / 16);
        pointer-events:none;
    }
    @media screen and (min-width: 992px) {
        position: fixed;
        max-height: 80vh;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-top: calc(30rem/16);
        padding-bottom: calc(55rem/16);
        left: 0;
        right: 0;
        top: calc(74rem/16);
        height: calc(662rem/16);
        opacity: 0;
        transform: translateX(100%);
        transition: opacity 120ms, visibility .001ms 120ms, transform .001ms 120ms;
        font-size: calc(30rem / 16);
    }
    @media screen and (min-width: 992px) and (max-width: 1399px) {
        padding-top: calc(10rem/16);
    }
}
.main-nav.is-nav-white .main-nav__sub-nav {
    @media screen and (min-width: 992px) {
        top: var(--main-nav-height);
    }
}
.main-nav__nav-item:hover:before {
    left: 0;
    right: 0;
    height: calc(79rem/16);
    top: calc(-21rem/16);
    width: 100%;
    background-color: #fff;
    z-index: -3;
}
.main-nav__nav-wrapper--right .main-nav__nav-item:hover:before {
    display:none;
}
.main-nav__sub-nav-wrapper {
    height:100%;
    @media screen and (max-width: 991px) {
        overflow-y: scroll;
        height:100%;
        padding-bottom: calc(20rem/16);
    }
}
.main-nav__sub-nav__title {
    font-size: calc(30rem/16);
    color: var(--color-primary-light);
    margin-bottom: calc(25rem/16);
    text-transform:uppercase;
    @media screen and (max-width: 1399px) and (min-width: 992px){
        margin-bottom: calc(10rem/16);
        font-size: calc(20rem/16);
    }
    @media screen and (max-width: 991px) {
        margin-bottom: calc(20rem/16);
        font-size: calc(22rem/16);
        line-height: 1.2;
        color: var(--color-primary);
    }
}
.main-nav__sub-nav__link {
    font-size: calc(23rem/16);
    line-height: calc(33/26);
    padding: calc(10rem/16) 0;
    @media screen and (max-width: 1399px) and (min-width: 992px){
        font-size: calc(18rem/16);
        padding: calc(5rem/16) 0;
    }
    @media screen and (max-width: 991px) {
        font-size: calc(18rem/16);
        text-transform: none;
    }
}
.main-nav__sub-nav__title, .main-nav__sub-nav__link {
    transition: color 0.2s ease;
}
.main-nav__sub-nav__title:hover {
    color: var(--color-primary-dark);
}

.main-nav__simple-img-teaser__embed--square {
    padding-top:100%;
}
.main-nav__simple-img-teaser__embed--landscape {
    padding-top: 42.17413%;
}
.main-nav__simple-img-teaser .simple-img-teaser__title {
    font-size: calc(22rem/16);
}
.main-nav__simple-img-teaser.simple-img-teaser:hover .simple-img-teaser__title:after {
    opacity:0;
}
.main-nav__sub-nav--level-3 .main-nav__sub-nav__title {
    color: #fff;
    @media screen and (max-width: 991px) {
        color: var(--color-primary);
    }
}
.main-nav__sub-nav--go-back {
    position:absolute;
    bottom:0;
    width:100%;
    left:0;
    right:0;
    border-radius:0;
    height: calc(50rem/16);
    display:flex;
    justify-content:center;
    text-transform:uppercase;
    font-size: calc(18rem/16);
    z-index: 1;
}

.main-nav__sub-nav--level-3 {
    visibility: hidden;
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    z-index: 100;
    @media screen and (min-width: 992px) {
        width: 60%;
        position: fixed;
        padding: calc(30rem/16) calc(50rem/16);
        right: 0;
        height: 100%;
        opacity: 0;
        transform: translateX(100%);
        transition: opacity 120ms, visibility .001ms 120ms, transform .001ms 120ms;
        font-size: 1rem;
        background-color: var(--color-primary-light);
        top:0;
    }
    @media screen and (max-width: 991px) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        padding: 6.25rem calc(47rem/16);
        background-color: var(--color-primary-light);
        overflow-y: scroll;
    }
}
.main-nav__sub-nav--level-3 .main-nav__sub-nav__link {
    font-size: calc(16rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem/16);
    }
}
@media screen and (min-width: 768px) {
    .main-nav__sub-nav--level-3 .main-nav__sub-nav__link:hover {
        color: var(--color-primary-dark);
    }
    .main-nav__sub-nav__item:hover {
        background-color: var(--color-primary-light);
        color:#fff;
    }
}
.main-nav__sub-nav__item.is-open .main-nav__sub-nav--level-3, .main-nav__nav-item.is-open .main-nav__sub-nav {
    visibility: visible;
    z-index: 101;
    pointer-events:initial;
    transform: translateX(0);

    @media screen and (max-width: 991px) {
        transition: transform 120ms;
        display:block;
    }

    @media screen and (min-width: 992px) {
        opacity: 1;
        transition: opacity 120ms;
    }
}
.main-nav__sub-nav__item:hover .main-nav__sub-nav--level-3, .main-nav__nav-item:hover .main-nav__sub-nav {
    @media screen and (min-width: 992px) {
        visibility: visible;
        z-index: 101;
        transform: translateX(0);
        pointer-events: initial;
        opacity: 1;
        transition: opacity 120ms;
    }
}

.main-nav__sub-nav__regions-item {
    margin-bottom: calc(30rem/16);
}
.main-nav__sub-nav__regions {
    @media screen and (min-width: 992px) {
        font-size: 1rem;
        color: var(--color-text-default);
        font-family: var(--font-default);
    }
}
.main-nav__sub-nav__regions-title {
    text-transform:uppercase;
    font-family: var(--font-default-bold);
    margin-bottom: calc(20rem/16);
}
.main-nav__sub-nav__regions-list-item {
    padding-top: calc(5rem/16);
    padding-bottom: calc(5rem/16);
}
.main-nav__sub-nav__regions-img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: auto;
    max-width: 32vw;
    margin-top: calc(200rem/16);
    z-index: -1;
}
.navbar-container--regions {
    height:100%;
    @media screen and (min-width: 1200px) {
        position: relative;
    }
}