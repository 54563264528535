.accordion-item {
    box-shadow: 0 0.125rem 0.5625rem rgba(0,0,0,0.1);
    margin-bottom: calc(20rem / 16);
}
.accordion-item:target {
    margin-top: calc(-200rem/16);
    padding-top: calc(200rem/16);
    box-shadow: none;
    position: relative;
}
.accordion-item:target::after {
    content: "";
    position: absolute;
    inset: calc(200rem/16) 0 0 0;
    box-shadow: 0 0.125rem 0.5625rem rgba(0,0,0,0.1);
}
.accordion-item__header-link {
    display: block;
    padding: .5rem 0;

    @media screen and (min-width: 768px) {
        padding: 1.5rem 0;
    }
}
.accordion-item__toggle {
    position: relative;
    color: var(--color-primary);
    font-size: calc(20rem / 16);
    display: flex;
    padding-right: calc(30rem / 16);
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
        font-size: calc(14rem / 16);
        padding-right: calc(10rem/16);
    }
}
.accordion-item__title {
    padding: 0 calc(30rem / 16);
    margin-bottom: 0;
    line-height: 1;
    @media screen and (max-width: 767px) {
        padding: calc(0rem/16) calc(10rem/16);
        font-size: calc(16rem/16);
    }
}

.accordion-item__body {
    padding: calc(30rem / 16);
    border-top: 1px solid var(--color-dark-grey);
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
        font-size: calc(14rem/16);
    }
}

.accordion-item__toggle-icon {
    transition: transform 0.3s ease;
}

.accordion-item__header-link:not(.collapsed) .accordion-item__toggle-icon {
    transform: rotate(45deg);
}
