.form-group {
    position: relative;
}
.form-group.has-value .floating-label {
    background-color: #ffffff;
    padding-right: calc(20rem/16);
    @media screen and (max-width: 767px) {
        padding-left: calc(10rem/16);
        padding-bottom: calc(5rem/16);
    }
}