.table--primary thead th, .wysiwyg table thead th {
    background-color: var(--color-primary);
    color: #ffffff;
    border:none;
}
.table--primary, .wysiwyg table {
    font-size: calc(16rem/16);
    text-align: left;
}

.table-responsive.table--btob {
    @media screen and (min-width: 1200px) {
        display: table;
    }
}
.table--btob td,
.table--btob th {
    vertical-align: middle;

    @media screen and (max-width: 1199px) {
        padding: calc(15rem/16);
    }
}
.table--btob td,
.table--btob th {
    border-top: none;
}
.table--btob tr {
    border: 1px solid #c8c8c8;
}