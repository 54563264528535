.ribbon {
    padding:calc(6rem/16) calc(11rem/16);
    z-index: 2;
    color: #fff;
    font-size:calc(14rem/16);
    font-family:var(--font-default-bold);
    line-height: 1.2;
    background:var(--color-primary);
    position:relative;
    @media screen and (max-width: 767px) {
        font-size: calc(13rem/16);
        padding:calc(6rem/16);
    }
}
.ribbon:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: calc(-8rem/16);
    width: 0;
    height: 0;
    z-index: -1;
    white-space: normal;
    border: calc(7rem/16) solid var(--color-primary);
    border-bottom-color: transparent;
    border-right-color: transparent;
}
.ribbon--hochkoenig { background:var(--color-hochkoenig);}
.ribbon--hochkoenig:before {
    border: calc(7rem/16) solid var(--color-hochkoenig);
    border-bottom-color: transparent;
    border-right-color: transparent;
}
.ribbon--sportwelt { background: var(--color-sbg-sportwelt); }
.ribbon--sportwelt:before {
    border: calc(7rem/16) solid var(--color-sbg-sportwelt);
    border-bottom-color: transparent;
    border-right-color: transparent;
}
.ribbon--schladming { background:var(--color-schladming-dachstein); }
.ribbon--schladming:before {
    border: calc(7rem/16) solid var(--color-schladming-dachstein);
    border-bottom-color: transparent;
    border-right-color: transparent;
}
.ribbon--gastein { background:var(--color-gastein); }
.ribbon--gastein:before {
    border: calc(7rem/16) solid var(--color-gastein);
    border-bottom-color: transparent;
    border-right-color: transparent;
}
.ribbon--grossarltal { background:var(--color-grossarltal); }
.ribbon--grossarltal:before {
    border: calc(7rem/16) solid var(--color-grossarltal);
    border-bottom-color: transparent;
    border-right-color: transparent;
}
.ribbon--secondary { background:var(--color-secondary);}
.ribbon--secondary:before {
    border: calc(7rem/16) solid var(--color-secondary);
    border-bottom-color: transparent;
    border-right-color: transparent;
}