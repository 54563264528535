.weather-item__title {
    line-height: 1;
    position:relative;
    font-size: calc(30rem/16);
}
.weather-item__title:after {
    content:'';
    height: calc(4rem/16);
    background-color: var(--color-secondary);
    display: block;
    bottom: 0;
    margin: calc(40rem/16) 0 calc(20rem/16);
    left: 0;
    width: calc(40rem/16);
    right: 0;
    opacity:0;
    @media screen and (max-width: 767px) {
        margin: calc(20rem/16) 0 calc(10rem/16);
    }
}
.weather-item.is-in-viewport .weather-item__title:after {
    animation: 0.5s titleBlockLine 0.2s ease-in-out forwards;
}

@keyframes titleBlockLine {
    0% {
        opacity:0;
        transform: scale(0);
    }
    50% {
        opacity:1;
    }
    100% {
        transform: scale(1);
        opacity:1;
    }
}