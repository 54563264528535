.img-teaser__embed--portrait:before {
    padding-top: calc( 420 / 330 * 100%);
}
.img-teaser__embed--landscape:before {
    padding-top: calc( 335 / 700 * 100%);
    @media screen and (max-width: 767px) {
        padding-top: 100%;
    }
}
.img-teaser__embed--square:before,  .img-teaser__embed--logo:before {
    padding-top: calc( 335 / 335 * 100%);
}
.img-teaser {
    position: relative;
    cursor:pointer;
    box-shadow: 0 calc(20rem/16) calc(20rem/16) 0 rgba(35,59,72,0.3);
}
.img-teaser--transformed {
    margin-top: calc(50rem/16);
}
.img-teaser:before {
    background: linear-gradient(180deg, rgba(47,66,80,0) 0%, rgba(47, 66, 80, 0.94) 100%);
    content:'';
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 1;
    transition: opacity 0.7s ease;
}
.img-teaser__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    color:#fff;
    padding: calc(20rem/16) calc(23rem/16);
    text-align:center;
    @media screen and (max-width: 1199px) {
        padding: calc(20rem/16) calc(10rem/16);
    }
}
.img-teaser__title {
    font-size: calc(22rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(28/22);
    text-shadow: 0 calc(2rem/16) calc(30rem/16) rgba(0,0,0,0.3);
    margin-bottom:0;
    text-transform:uppercase;
    position: relative;
    transition: transform 0.4s ease-out;
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}
.img-teaser__content{
    line-height: calc(23/18);
    transition: transform 0.4s ease-out;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    font-size: calc(17rem/16);

    @media screen and (max-width: 1199px) {
        font-size: calc(14rem/16);
    }
}
.img-teaser--text-left .img-teaser__body{
    text-align: left;
}
.img-teaser--text-left .img-teaser__title:after {
    margin: 0;
}
.img-teaser--text-smaller .img-teaser__title {
    font-size: calc(20rem / 16);
    line-height: calc(25 / 20);
    @media screen and (max-width: 1199px) {
        font-size: calc(18rem/16);
    }
}
.img-teaser--text-smaller .img-teaser__content {
    max-width: calc(510rem/16);
    text-transform: unset;
}
.img-teaser--landscape.img-teaser--text-smaller .img-teaser__content {
    @media screen and (min-width: 1220px) {
        max-width: 65%;
    }
}
.img-teaser__ribbon {
    transition: transform 0.4s ease-out;
    margin-bottom: calc(15rem/16);
}
.img-teaser--landscape .img-teaser__link-wrapper {
    @media screen and (min-width: 1200px) {
        position:absolute;
        right: calc(23rem/16);
        margin-top:0;
    }
}
.img-teaser__link-wrapper {
    margin-top: calc(10rem/16);
}
.img-teaser__link-wrapper .btn {
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        padding: calc(10rem/16);
    }
}
.img-teaser__link {
    @media screen and (max-width: 767px) {
        padding: calc(5rem/16);
        font-size: calc(12rem/16);
    }
}
.img-teaser-list-area__list {
    @media screen and (max-width: 767px) {
        padding-bottom: calc(60rem/16);
    }
}
.img-teaser__badge {
    background-color: var(--color-secondary);
    color:#fff;
    padding: calc(10rem/16);
    position: absolute;
    right:0;
    top:0;
}
@media screen and (min-width: 768px) {
    .img-teaser__media {
        transition: transform 0.5s ease-in-out;
    }
    .img-teaser:hover .img-teaser__media {
        transform: scale(1.05);
    }
    .img-teaser:hover:before {
        opacity:0.6;
    }
    .img-teaser:hover .img-teaser__title {
        transform: translateY(calc(-30rem/16));
    }
    .img-teaser:hover .img-teaser__content {
        transform: translateY(calc(-20rem/16));
    }
    .img-teaser:hover .img-teaser__ribbon {
        transform: translateY(calc(-20rem/16));
    }
    .img-teaser__title:after {
        content: "";
        height: .25rem;
        background-color: var(--color-primary);
        display: block;
        bottom: 0;
        margin: 0 auto;
        left: 0;
        width: 2.5rem;
        right: 0;
        transform: scaleX(0);
        opacity: 0;
        transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    }
    .img-teaser__title--hochkoenig:after { background-color: var(--color-hochkoenig);}
    .img-teaser__title--grossarltal:after { background-color: var(--color-grossarltal);}
    .img-teaser__title--schladming:after { background-color: var(--color-schladming-dachstein);}
    .img-teaser__title--sportwelt:after { background-color: var(--color-sbg-sportwelt);}
    .img-teaser__title--gastein:after { background-color: var(--color-gastein);}

    .img-teaser:hover .img-teaser__title:after {
        opacity:1;
        transform: scaleX(1) translateY(.2rem);
    }
}
@media screen and (min-width: 1199px) {
    .img-teaser:hover .img-teaser__title {
        transform: translateY(calc(-50rem/16));
    }
    .img-teaser:hover .img-teaser__content {
        transform: translateY(calc(-30rem/16));
    }
    .img-teaser:hover .img-teaser__ribbon {
        transform: translateY(calc(-50rem/16));
    }
    .img-teaser:hover .img-teaser__title:after {
        opacity:1;
        transform: scaleX(1) translateY(calc(8rem/16));
    }
}