:root {
    --item-width: calc(940rem/16);
    --item-width-md: calc(500rem/16);
    --item-width-xs: calc(240rem/16);
    --item-padding-bottom: calc(80rem/16);
}

.img-slider__embed {
    padding-top: calc( 500 / 940 * 100%);
}
.img-slider {
    position:relative;
}
.img-slider .slick-slide {
    width:var(--item-width);
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        width:var(--item-width-md);
    }
    @media screen and (max-width: 767px) {
        width:var(--item-width-xs);
    }
}
.img-slider .slick-slide:not(.slick-current) {
    transform: scale(0.7) translateX(14%);;
    transition: transform 0.8s ease;
}
.img-slider .slick-slide.slick-current + .slick-slide {
    transform: scale(0.7) translateX(-14%);;
}
.img-slider .slick-slide .img-slider__item:before {
    content:'';
    position:absolute;
    background-color: #fff;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index: 1;
    opacity:0.4;
    transition: opacity 0.4s ease;
}
.img-slider .slick-slide.slick-current {
    transform: scale(1) translateX(0);
    transition: transform 0.8s ease;
}
.img-slider .slick-slide.slick-current .img-slider__item:before {
    opacity:0;
}
.img-slider__item {
    padding-top: calc(30rem/16);
    padding-bottom: calc(80rem/16);
}
.img-slider__img-wrapper {
    overflow:visible;
}
.img-slider .slick-slide .img-slider__img-wrapper:before {
    content:'';
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    top:0;
    opacity:0;
    box-shadow: 0 12px 20px 0 rgba(35,59,72,0.3);
    transition: opacity 0.5s ease;
    z-index: -1;
}
.img-slider .slick-slide.slick-current .img-slider__img-wrapper:before {
    opacity:1;
}
.img-slider__arrow-wrapper {
    position:absolute;
    right: calc((100vw - var(--item-width)) /2 - calc(10rem/16));
    bottom: var(--item-padding-bottom);
    transform: translateY(50%);
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        right: calc((100vw - var(--item-width-md)) /2 - calc(10rem/16));
    }
    @media screen and (max-width: 767px) {
        right: calc((100vw - var(--item-width-xs)) /2 - calc(10rem/16));
    }
}
.img-slider__open-btn {
    position: absolute;
    left:0;
    top: calc(30rem/16);
    width: 4rem;
    height:4rem;
    z-index: 2;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size: calc(33rem/16);
    color: var(--color-primary);
    transform: scale(0);
    transform-origin: top left;
    transition: transform 0.5s ease, color 0.4s ease;
}
.img-slider .slick-slide.slick-current .img-slider__open-btn {
    transform: scale(1);
}
.img-slider .slick-slide .img-slider__open-btn:hover {
    transform: scale(1.2);
    color: var(--color-primary-dark);
    transition: transform 0.3s ease, color 0.4s ease;
}
.img-slider__open-btn:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: calc(100rem/16) calc(100rem/16) 0 0;
    border-color: rgba(255,255,255,0.7) transparent transparent transparent;
    content: '';
    left: 0;
    top: 0;
    right: 0;
    position: absolute;
}