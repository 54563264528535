.wysiwyg-with-medium__body--left {
    @media screen and (min-width: 1450px) {
        padding-top: calc(40rem/16);
        padding-right: calc(50rem/16);
    }
}

.wysiwyg-with-medium__body--right {
    @media screen and (min-width: 1450px) {
        padding-top: calc(40rem/16);
        padding-left: calc(50rem/16);
    }
}

.wysiwyg-with-medium__body {
    @media screen and (max-width: 767px) {
        margin-bottom: calc(30rem/16);
    }
}