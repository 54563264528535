.form-group-search .form-control {
    border:none;
    padding:0;
    font-size: calc(16rem/16);
    border-bottom: calc(2rem/16) solid var(--color-primary-light);
    font-family:var(--font-default);
    height: calc(40rem/16);
}
.form-group-search:before {
    position:absolute;
    pointer-events:none;
    content: var(--icon-search);
    display:block;
    font-family: 'iconfont';
    speak: none;
    font-style: normal;
    font-weight: 400;
    color: var(--color-primary-light);
    right: 0;
    bottom:calc(5rem/16);
}
.form-group-search {
    position: relative;
}