.lg-backdrop {
    background-color: rgba(0, 24, 37, 0.91);
}
.lg-toolbar, .lg-actions .lg-prev, .lg-actions .lg-next {
    background-color:transparent;
    color:#fff;
}
.lg-sub-html {
    background-color:transparent;
    font-size:calc(15rem/16);
    font-family:var(--font-default);
    color: #fff;
    padding: calc(40rem/16) calc(0rem/16) calc(60rem/16);
    @media screen and (max-width: 767px) {
        padding:calc(10rem/16);
    }
}

.webcam-lightbox--open .lg-sub-html {
    padding: 1rem calc(0rem/16);
}

.webcam-lightbox--open .lg-outer .lg-has-iframe .lg-video {
    @media screen and (max-width: 767px) {
        padding-bottom: unset;
        height: 95vh;
    }
}

.webcam-lightbox--open .lg-video-cont.lg-has-iframe {
    @media screen and (max-width: 767px) {
        max-width: 85% !important;
    }
}

.lg-actions .lg-prev, .lg-actions .lg-next {
    font-size:calc(35rem/16);
    padding:calc(15rem/16) calc(20rem/16);
    @media screen and (max-width: 767px) {
        top:50%;
        transform: translateY(-50%);
    }
}
.lg-toolbar .lg-close, .lg-actions .lg-next, .lg-actions .lg-prev {
    color:#fff;
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0);
}
.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
    color:#fff;
}
.lg-toolbar .lg-close:hover {
    color:#fff;
}
.lg-toolbar {
    z-index: 9999;
    height: calc(50rem/16);
    color: #fff;
}
.lg-toolbar #lg-counter {
    bottom: 0;
    z-index: 9;
    color: #fff;
    width: 100%;
    text-align:center;
    font-size: calc(22rem/16);
    font-family: var(--font-default-bold);
    padding-bottom: calc(10rem/16);
    text-shadow: 0 0 3px rgba(0,0,0,0.3);
    position: absolute;
    pointer-events: none;
}
.lg-toolbar .lg-close {
    position: absolute;
    right: 0;
    top: 0;
    width: 4.375rem;
    z-index: 9;
    box-shadow: none;
    border: none;
    color: #fff;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
    transition: all .2s ease;
    margin-top:calc(10rem/16);
    margin-right: calc(10rem/16);
}
.lg-toolbar .lg-close:after {
    content: var(--icon-close);
}
.lg-actions .lg-next:before {
    content: var(--icon-arrow-right);
}
.lg-actions .lg-prev:after {
    content:var(--icon-arrow-left);
}
.lightbox-video__iframe {
    width:100%;
    height:calc(500rem/16);
    @media screen and (max-width: 767px) {
        height:auto;
    }
}
.lightbox-content__title {
    font-size: calc(32rem/16);
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size: calc(22rem/16);
    }
}
.lightbox-content__title:after {
    content: "";
    height: .25rem;
    background-color: var(--color-secondary);
    display: block;
    bottom: 0;
    margin: .75rem auto;
    left: 0;
    width: 2.5rem;
    right: 0;
}
.lightbox-content__text {
    font-family: var(--font-default);
    font-size: calc(18rem/16);
}
.lg-outer .lg-has-iframe .lg-video {
    padding-bottom: 59.25%;
}