.custom-select__wrapper {
    position:relative;
    width: 100%;
}
.custom-select__wrapper select {
    appearance: none;
}
.custom-select__wrapper select::-ms-expand {
    display: none;
}
.custom-select__wrapper .custom-select__icon {
    right:calc(-15rem/16);
}
.custom-select__wrapper .custom-select__icon {
    position:absolute;
    right:calc(24rem/16);
    font-size:calc(15rem/16);
    color:var(--color-primary);
    pointer-events:none;
    top: 50%;
    transform: translateY(-50%) rotate(270deg);
}