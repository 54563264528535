.region-hotspot-area {
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 27%;
    overflow: hidden;
    @media screen and (max-width: 767px) {
        background-size: 50%;
    }
}
.region-hotspot__hotspot-container {
    position: relative;
    @media screen and (max-width: 767px) {
        transform: scale(1.6);
    }
}
.region-hotspot__hotspot-wrapper {
    overflow-x:hidden;
}
.region-hotspot__title {
    font-family: var(--font-default-bold);
    text-transform:uppercase;
    margin-bottom: calc(33rem/16);
    color: var(--color-primary-light);
}
.region-hotspot__btn {
    width: calc(22rem/16);
    height: calc(22rem/16);
    position:absolute;
    transform: translateX(-50%) translateY(-50%);
    color:#fff;
    border-radius: 50%;
    font-size: calc(15rem/16);
    @media screen and (max-width: 767px) {
        width: calc(12rem/16);
        height: calc(12rem/16);
    }
}
.region-hotspot__btn:hover, .region-hotspot__btn.is-active {
    z-index: 999;
}
.region-hotspot__btn:after {
    content:'';
    position:absolute;
    background-color:var(--color-primary);
    width: calc(12rem/16);
    height: calc(12rem/16);
    border-radius: 50%;
    top:50%;
    transform: translateY(-50%) translateX(-50%);
    left:50%;
    z-index: 1;
    @media screen and (max-width: 767px) {
        width: calc(6rem/16);
        height: calc(6rem/16);
    }
}
.region-hotspot__btn:before {
    content:'';
    position:absolute;
    background-color:var(--color-primary);
    opacity:0.3;
    width: calc(22rem/16);
    height: calc(22rem/16);
    border-radius: 50%;
    top:50%;
    transform: translateY(-50%);
    transition: transform 0.3s ease;
    @media screen and (max-width: 767px) {
        width: calc(12rem/16);
        height: calc(12rem/16);
    }
}
.region-hotspot__btn:hover:before, .region-hotspot__btn.is-active:before {
    transform: translateY(-50%) scale(1.3);
}
.region-hotspot__btn-text {
    opacity:0;
    transform: translateY(calc(10rem/16));
    position:absolute;
    bottom: 100%;
    margin-bottom: calc(15rem/16);
    margin-left: calc(10rem/16);
    display: block;
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events:none;
}

.region-hotspot__btn:hover .region-hotspot__btn-text,
.region-hotspot__btn.is-active .region-hotspot__btn-text{
    opacity:1;
    transform: translateY(0);
}
.region-hotspot__btn-text .ribbon {
    display:block;
}
.region-hotspot__list-item-link {
    font-size: calc(20rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(25/20);
    margin-bottom: calc(20rem/16);
    display: block;
    transition: color 0.2s ease;
    @media screen and (max-width: 767px) {
        margin-bottom: calc(10rem/16);
        font-size: calc(18rem/16);
    }
}
.region-hotspot__btn--sportwelt:after, .region-hotspot__btn--sportwelt:before { background-color: var(--color-sbg-sportwelt); }
.region-hotspot__btn--schladming:after, .region-hotspot__btn--schladming:before { background-color: var(--color-schladming-dachstein); }
.region-hotspot__btn--grossarltal:after, .region-hotspot__btn--grossarltal:before { background-color: var(--color-grossarltal); }
.region-hotspot__btn--gastein:after, .region-hotspot__btn--gastein:before { background-color: var(--color-gastein); }
.region-hotspot__btn--hochkoenig:after, .region-hotspot__btn--hochkoenig:before { background-color: var(--color-hochkoenig); }

.region-hotspot__list-item-link--sportwelt:hover, .region-hotspot__list-item-link--sportwelt.is-active { color: var(--color-sbg-sportwelt); }
.region-hotspot__list-item-link--schladming:hover, .region-hotspot__list-item-link--schladming.is-active { color: var(--color-schladming-dachstein); }
.region-hotspot__list-item-link--grossarltal:hover, .region-hotspot__list-item-link--grossarltal.is-active { color: var(--color-grossarltal); }
.region-hotspot__list-item-link--gastein:hover, .region-hotspot__list-item-link--gastein.is-active { color: var(--color-gastein); }
.region-hotspot__list-item-link--hochkoenig:hover, .region-hotspot__list-item-link--hochkoenig.is-active { color: var(--color-hochkoenig); }

@media screen and (max-width: 767px) {
    .region-hotspot__list-item-link:before {
        content: '';
        position: relative;
        border-color: transparent transparent var(--color-primary);
        border-left: calc(10rem/16) solid transparent;
        border-bottom: calc(10rem/16) solid var(--color-primary);
        border-right: 0 solid transparent;
        border-top: 0 solid transparent;
        margin-bottom: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        bottom: calc(10rem/16);
    }
    .region-hotspot__list-item-link--sportwelt:before {
        border-color: transparent transparent var(--color-sbg-sportwelt);
        border-bottom: calc(10rem/16) solid var(--color-sbg-sportwelt);
    }
    .region-hotspot__list-item-link--schladming:before {
        border-color: transparent transparent var(--color-schladming-dachstein);
        border-bottom: calc(10rem/16) solid var(--color-schladming-dachstein);
    }
    .region-hotspot__list-item-link--grossarltal:before {
        border-color: transparent transparent var(--color-grossarltal);
        border-bottom: calc(10rem/16) solid var(--color-grossarltal);
    }
    .region-hotspot__list-item-link--gastein:before {
        border-color: transparent transparent var(--color-gastein);
        border-bottom: calc(10rem/16) solid var(--color-gastein);
    }
    .region-hotspot__list-item-link--hochkoenig:before {
        border-color: transparent transparent var(--color-hochkoenig);
        border-bottom: calc(10rem/16) solid var(--color-hochkoenig);
    }

}