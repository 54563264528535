.footer__main {
    background-color: var(--color-primary);
    color:#fff;
    padding: calc(50rem/16) 0;
}
.footer__link-list {
    display:flex;
    padding: calc(40rem/16) 0;
    @media screen and (max-width: 767px) {
        display:block;
    }
}
.footer__link-list__item {
    padding: 0 calc(10rem/16);
    text-transform: uppercase;
    font-size: calc(16rem/16);
    line-height: calc(19/16);
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        text-align:center;
        margin-top:calc(20rem/16);
    }
}
.footer__contact-list {
    display:flex;
    @media screen and (max-width: 767px) {
        display:block;
    }
}
.footer__contact-list__item {
    font-size: calc(18rem/16);
    line-height: calc(23/18);
    font-family: var(--font-default-bold);
    padding: 0 calc(16rem/16);
    display:flex;
    justify-content: center;
    align-items:center;
    @media screen and (max-width: 767px) {
        text-align:center;
        margin-top:calc(20rem/16);
    }
}
.footer__contact-list__item-icon {
    margin-right: calc(15rem/16);
    font-size: calc(24rem/16);
}
.footer__social-list {
    display:flex;
    align-items: center;
    justify-content: center;
}
.footer__social-list__item {
    padding: calc(40rem/16) calc(15rem/16);
    color: var(--color-primary);
    font-size: calc(30rem/16);
}
.footer--lp .footer__link-list {
    padding: 0;
}
.footer--lp .footer__social-list {
    border-top: 2px solid var(--color-primary);
}
.footer--lp .footer__social-list__item {
    padding: calc(30rem/16) calc(50rem/16);
    font-size: calc(45rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(15rem/16);
        font-size: calc(30rem/16);
    }
}
.footer--lp .footer__main {
    padding: calc(20rem/16) 0;
}
.footer__copyright {
    font-size: calc(14rem/16);
    @media screen and (max-width: 767px) {
        text-align: center;
    }
}
.footer__logo {
    border: 1px solid #fff;
}
.footer__meta {
    @media screen and (max-width: 767px) {
        padding-bottom: calc(30rem/16);
    }
}
.footer--lp .footer__meta {
    @media screen and (max-width: 767px) {
        padding-bottom: calc(0rem/16);
    }
}