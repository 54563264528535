.number-spinner, .number-spinner .input-group {
    height: calc(54rem/16);
    font-family: var(--font-default-bold);
}
.number-spinner--md, .number-spinner--md .input-group {
    height: calc(50rem/16);
}
.number-spinner__button {
    background-color:var(--color-primary);
    border-radius:0;
    color:#fff;
    font-size: calc(20rem/16);
    display:flex;
    justify-content:center;
    align-items:center;
    height: calc(54rem/16);
    width: calc(54rem/16);
    padding: calc(5rem/16);
    font-family: var(--font-default);
}
.number-spinner--md .number-spinner__button {
    height: calc(50rem/16);
    width: calc(50rem/16);
}
.number-spinner__input {
    height:100%;
    text-align:center;
    padding:0;
}
.number-spinner--grey .number-spinner__button {
    background-color: var(--color-dark);
}
.number-spinner--grey .number-spinner__input {
    border-color: var(--color-dark);
    color: var(--color-dark);
}

/* small number spinner */
.number-spinner--sm, .number-spinner--sm .input-group {
    height: calc(37rem/16);
}
.number-spinner--sm .number-spinner__button {
    height: calc(37rem/16);
    width: calc(37rem/16);
}
.number-spinner--sm .number-spinner__input {
    padding-left: 0;
}