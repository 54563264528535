.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}
.btn {
    font-family: var(--font-default-bold);
}
.btn[class*="btn-outline-"]:hover {
    color:#fff;
}
.btn-shadow {
    color: var(--color-dark);
    background-color:#fff;
    box-shadow: 0 calc(20rem/16) calc(34rem/16) 0 rgba(5,30,45,0.3);
    @media screen and (max-width: 767px) {
        box-shadow: 0 calc(5rem/16) calc(10rem/16) 0 rgba(5,30,45,0.3);
    }
}
.btn-sm {
    @media screen and (max-width: 767px) {
        padding: calc(13rem/16) calc(15rem/16);
    }
}
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}
.btn-facebook {
    background-color:#005AE1;
    color:#fff;
}
.btn-google{
    background-color:#CE2318;
    color:#fff;
}