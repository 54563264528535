.facts-slide {
    background-size: cover;
    position: relative;
    padding-top: calc(90rem/16);
    padding-bottom: calc(110rem/16);
    background-position: center;
    @media screen and (max-width: 767px) {
        padding-top: calc(50rem/16);
        padding-bottom: calc(50rem/16);
    }
}
.facts-slide:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background-color: rgba(255,255,255,0.7);
}