.text-with-two-img__embed--landscape-big {
    padding-top: calc( 506 / 821 * 100%);
}
.text-with-two-img__embed--landscape-small {
    padding-top: calc( 350 / 411 * 100%);
}
.text-with-two-img__embed--portrait {
    padding-top: calc( 600 / 410 * 100%);
}
.text-with-two-img__img-two--portrait {
    margin-top: -50%;
    margin-right: 10%;
    margin-left: 41%;
    @media screen and (min-width: 768px) {
        padding-bottom: calc(70rem/16);
    }
    @media screen and (max-width: 767px) {
        max-width: calc(200rem/16);
    }
}
.text-with-two-img__img-two--landscape {
    position:absolute;
    top:0;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    right: 0;
    left:0;
    max-width: 48.3%;
}
.text-with-two-img__title {
    line-height: 1;
    position:relative;
}
.text-with-two-img__title:after {
    content:'';
    height: calc(4rem/16);
    background-color: var(--color-secondary);
    display: block;
    bottom: 0;
    margin: calc(50rem/16) 0 calc(20rem/16);
    left: 0;
    width: calc(40rem/16);
    right: 0;
    opacity:0;
    @media screen and (max-width: 767px) {
        margin: calc(20rem/16) 0 calc(10rem/16);
    }
}
.text-with-two-img__title.text-md-right:after{
    @media screen and (min-width: 768px) {
        margin: calc(50rem/16) 0 calc(20rem/16) auto;
    }
}
.text-with-two-img-area.is-in-viewport .text-with-two-img__title:after {
    animation: 0.5s titleBlockLine 0.2s ease-in-out forwards;
}
.text-with-two-img__content {
    margin-bottom: calc(35rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(15rem/16);
    }
}
.text-with-two-img-area--text-left .text-with-two-img__images-wrapper, .text-with-two-img-area--text-left .text-with-two-img__content-wrapper {
    padding-top: calc(93rem/16);
    @media screen and (max-width: 767px) {
        padding-top: calc(20rem/16);
    }
}
.text-with-two-img__content-wrapper {
    padding-bottom: calc(40rem/16);
    @media screen and (max-width: 767px) {
        padding-top: calc(20rem/16);
    }
}
@keyframes titleBlockLine {
    0% {
        opacity:0;
        transform: scale(0);
    }
    50% {
        opacity:1;
    }
    100% {
        transform: scale(1);
        opacity:1;
    }
}