.toggle-switch {
    display:inline-block;
}
.toggle-switch__switch {
    background-color: var(--color-primary);
    border: calc(2rem/16) solid var(--color-primary);
    border-radius: calc(25rem / 16);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.toggle-switch__input {
    position: absolute;
    width: calc(1rem / 16);
    height: calc(1rem / 16);
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
.toggle-switch__option {
    height: 100%;
}
.toggle-switch__input-label {
    padding: 0.87rem calc(20rem / 16);
    height: 100%;
    display: block;
    line-height: 1.1;
    color: #fff;
    font-size: calc(18rem / 16);
    font-family: var(--font-default-bold);
}
.toggle-switch__input:checked + .toggle-switch__input-label {
    background-color: #fff;
    border-radius: calc(25rem / 16);
    color: var(--color-primary);
    box-shadow: 4px 0 10px 0 rgba(0, 0, 0, 0.2);
}
.toggle-switch__input:checked {
    background-color: #fff;
}
.toggle-switch__option + .toggle-switch__option {
    margin-left: calc(10rem/16);
}