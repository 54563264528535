.video-js .vjs-big-play-button {
    width:calc(90rem/16);
    height: calc(90rem/16);
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: #fff;
    top: 50%;
    left: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%) scale(1) ;
    transition: transform 400ms ease;
    box-shadow: 0 12px 20px 0 rgba(35,59,72,0.3);
    @media screen and (max-width: 767px) {
        width:calc(60rem/16);
        height: calc(60rem/16);
    }
}
.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    content: var(--icon-play);
    font-family: iconfont;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: calc(90rem / 16);
    height: auto;
    width: auto;
    color: #fff;
    transition: color 400ms ease;
    @media screen and (max-width: 767px) {
        font-size: calc(60rem / 16);
    }
}
.vjs-has-started .vjs-big-play-button {
    opacity:0;
}

/* hover states */
@media screen and (min-width: 768px) {
    .video-js .vjs-big-play-button:focus,
    .video-js:hover .vjs-big-play-button {
        transform-origin: center;
        transform: translate(-50%, -50%) scale(1.2);
        transition: transform 300ms ease;
    }
}