.title-block {
    position: relative;
    margin-bottom: calc(40rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(20rem/16);
    }
}
.title-block.title-block--live-console {
    width: 85%;
    margin: 0 auto;

    @media screen and (min-width: 768px) and (max-width: 1400px) {
        width: 75%;
    }
    @media screen and (max-width: 767px) {
        width: 95%;
    }
}
.title-block--big-line-spacing {
    margin-bottom: calc(20rem/16);
}
.title-block__title, .title-block__sub-title {
    transform:translateY(calc(20rem/16));
    transition: transform 0.4s ease-out;
}
.title-block.is-in-viewport .title-block__title, .title-block.is-in-viewport .title-block__sub-title{
    transform:translateY(0);
}
.title-block__title {
    position:relative;
    line-height: 1;
}
.title-block__title:after {
    content:'';
    height: calc(4rem/16);
    background-color: var(--color-secondary);
    display: block;
    bottom: 0;
    margin: calc(20rem/16) 0 calc(20rem/16);
    left: 0;
    width: calc(40rem/16);
    right: 0;
    opacity:0;
}
.title-block.title-block--main {
    @media screen and (max-width: 767px) {
        transform: none;
    }
}
.title-block--main .title-block__title {
    @media screen and (min-width: 768px) {
        font-size: calc(40rem/16);
    }
}
.title-block--main .title-block__sub-title {
    font-size: calc(26rem/16);
    color: var(--color-text-default);
}
.title-block--uppercase {
    text-transform: uppercase;
}

.title-block.text-center .title-block__title:after {
    margin: calc(15rem/16) auto calc(20rem/16);
}
.title-block--big-line-spacing .title-block__title:after {
    margin: calc(50rem/16) 0 calc(5rem/16);
    @media screen and (max-width: 767px) {
        margin: calc(20rem/16) 0 calc(20rem/16);
    }
}
.title-block--big-line-spacing.text-center .title-block__title:after {
    margin: calc(50rem/16) auto calc(5rem/16);
    @media screen and (max-width: 767px) {
        margin: calc(20rem/16) auto calc(20rem/16);
    }
}
.title-block.is-in-viewport .title-block__title:after {
    animation: 0.5s titleBlockLine 0.2s ease-in-out forwards;
}
.title-block__sub-title {
    color: var(--color-primary);
}
.title-block--main .title-block__content {
    @media screen and (min-width: 1200px) {
        width: 60%;
        margin: 0 auto;
    }
}
.title-block__content {
    margin-top: calc(30rem/16);
    text-transform: none;
}
@keyframes titleBlockLine {
    0% {
        opacity:0;
        transform: scale(0);
    }
    50% {
        opacity:1;
    }
    100% {
        transform: scale(1);
        opacity:1;
    }
}
