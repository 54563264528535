.expandable-block__content-wrapper {
    position: relative;
    overflow-y: auto;
    max-height: 320px;
}
.expandable-block__content-wrapper--small {
    max-height: 60px;
}
.expandable-block.is-expandable .expandable-block__content-wrapper {
    max-height: none;
}
.expandable-block.is-collapsed .expandable-block__content-wrapper {
    display: block;
    height: 320px;
    padding: 0;
    overflow: hidden;
}
.expandable-block.is-collapsed .expandable-block__content-wrapper--small {
    height: 70px;
}
.expandable-block.is-collapsed .expandable-block__content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
}
.expandable-block__content-wrapper:after {
    content: '';
    position: absolute;
    bottom: calc(37rem/16);
    left: 0;
    right: 0;
    height: 100px;
    visibility: hidden;
    z-index: 1;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, var(--color-white) 100%);
}
.expandable-block__content-wrapper--small:after {
    height: 40px;
}
.expandable-block__more {
    display: none;
    background-color:var(--color-white);
    font-size:calc(16rem/16);
    position: absolute;
    left:0;
    bottom:0;
    right:0;
    z-index: 2;
}
.expandable-block.is-expandable.is-collapsed .expandable-block__more {
    display: block;
}
.expandable-block.is-expandable.is-collapsed .expandable-block__content-wrapper:after {
    visibility: visible;
}

/*is-not-expandable*/
.expandable-block.is-not-expandable .expandable-block__content-wrapper {
    height: auto;
    max-height: none;
    overflow: visible;
}
.expandable-block.is-not-expandable .expandable-block__content {
    position: static;
    height: auto;
    width: auto;
    max-height: none;
}
.expandable-block.is-not-expandable .expandable-block__more {
    display: none;
}
.expandable__btn {
    display: block;
    margin: 0 auto;
    font-family: var(--font-default-bold);
}