html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default);
}
b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
    @media screen and (max-width: 767px) {
        hyphens: auto;
    }
}

h1, .h1 {
    font-size: calc(50rem/16);
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size: calc(34rem/16);
        line-height: calc(40/34);
    }
}
h2, .h2 {
    font-size: calc(40rem/16);
    line-height: calc(55/40);
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size: calc(23rem/16);
        line-height: calc(55/30);
    }
}
h3, .h3 {
    font-size: calc(26rem/16);
    line-height: calc(33/25);
    font-family: var(--font-default);
    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
    }
}
h4, .h4 {
    font-size: calc(22rem/16);
    line-height: calc(26/22);
    font-family: var(--font-default-bold);
}
h5, .h5 {
    font-size: calc(18rem/16);
}
h6, .h6 {
    font-size: 1em;
}