.text-with-three-media__embed--landscape {
    padding-top: calc( 462 / 821 * 100%);
}
.text-with-three-media__embed--portrait {
    padding-top: calc( 641 / 457 * 100%);
}
.text-with-three-media__left-side {
    @media screen and (min-width: 768px) {
        margin-top:  22%;
    }
}
.text-with-three-media__content-wrapper {
    padding-top: calc(75rem/16);
    @media screen and (max-width: 767px) {
        padding-top: calc(20rem/16);
    }
}
.text-with-three-media__media {
    position:relative;
}
.text-with-three-media__media:before {
    content:'';
    position: absolute;
    width: 100%;
    height:100%;
    top:0;
    left:0;
    background-color:  var(--color-primary-dark);
    opacity:0;
    z-index: 1;
    pointer-events:none;
    transition: opacity 0.3s ease;
}
.text-with-three-media__media--image:after {
    content:var(--icon-search);
    font-family: iconfont;
    position: absolute;
    width: 100%;
    height:100%;
    top:0;
    pointer-events:none;
    left:0;
    font-size: calc(60rem/16);
    opacity:0;
    color:#fff;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0);
    transition: opacity 0.3s ease, transform 0.3s ease;
}
.text-with-three-media__media:hover:before {
    opacity:0.2;
}
.text-with-three-media__img {
    transition: transform 0.6s ease;
}
.text-with-three-media__media:hover .text-with-three-media__img {
    transform: scale(1.02);
}
.text-with-three-media__media--image:hover:after {
    opacity:1;
    transform: scale(1);
}
.text-with-three-media__play-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: calc(70rem/16);
    border: none;
    opacity:0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: #fff;
    transform-origin: center;
    transition: transform 0.3s ease;
    filter: drop-shadow(0 0 .9375rem rgba(0,0,0,.3));
    z-index: 2;
}
.text-with-three-media__media--image:hover:after {
    opacity:1;
    transform: scale(1);
}
.text-with-three-media__media--video:hover .text-with-three-media__play-btn {
    opacity:1;
    transform: scale(1.1);
}
.text-with-three-media__title {
    line-height: 1;
    position:relative;
}
.text-with-three-media__title:after {
    content:'';
    height: calc(4rem/16);
    background-color: var(--color-secondary);
    display: block;
    bottom: 0;
    margin: calc(50rem/16) 0 calc(20rem/16);
    left: 0;
    width: calc(40rem/16);
    right: 0;
    opacity:0;
    position: relative;
    @media screen and (max-width: 767px) {
        margin: calc(20rem/16) 0 calc(10rem/16);
    }
}
.text-with-three-media-area.is-in-viewport .text-with-three-media__title:after {
    animation: 0.5s titleBlockLine 0.2s ease-in-out forwards;
}