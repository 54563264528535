.demi-infrastructure-overview-content-block .filter-categories .row{
  align-items: flex-end;
}

.filter-categories label,
.demi-event__filterbox label,
.demi-event__filterbox .custom-checkbox__text{
  font-size: calc(18rem/16);
}

.demi-event__filterbox{
  max-width: calc(800rem/16);
  margin: 0 auto;
}

.demi-event__filterbox>.row{ /* aling-items center passt am öftesten zu den verschiedenen Filter Konfigs */
  align-items: center;
}

.demi-event__filterbox-btn{
  margin-top: calc(10rem/16);
}