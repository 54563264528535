.contact-teaser__embed:before {
    padding-top: calc( 270 / 330 * 100%);
}

.contact-teaser {
    position: relative;
    box-shadow: 0 calc(20rem/16) calc(40rem/16) 0 rgba(35,59,72,0.1);
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 767px) {
        margin-bottom: calc(10rem/16);
    }
}
.contact-teaser__img-wrapper {
    position: relative;
}
.contact-teaser__body {
    padding: calc(20rem/16);
    background-color: #ffffff;
    z-index: 2;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
}
.contact-teaser__title {
    font-size: calc(20rem/16);
}
.contact-teaser__content {
    font-size: calc(16rem/16);
    line-height: calc(19rem/16);
}
.contact-teaser__collapse-box {
    background-color: var(--color-primary-light);
    padding: calc(50rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
.contact-teaser__website {
    word-break:break-word;
    font-size: calc(14rem/16);
    color: var(--color-primary);
}
.contact-teaser__ribbon {
    position: absolute;
    top: calc(10rem/16);
    margin-bottom: calc(10rem/16);
}
.contact-teaser__ribbon + .contact-teaser__ribbon {
    top: 3rem;
}

@media screen and (min-width: 768px) {
    .contact-teaser__img-wrapper:before {
        background: linear-gradient(180deg, rgba(47,66,80,0) 0%, rgba(47, 66, 80, 0.94) 100%);
        content:'';
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index: 1;
        opacity: 0;
        transition: opacity 0.7s ease;
    }
    .contact-teaser:hover .contact-teaser__img-wrapper:before {
        opacity:0.6;
    }

    .contact-teaser__img {
        transition: transform 0.4s ease-in-out;
    }
    .contact-teaser:hover .contact-teaser__img {
        transform: scale(1.05);
    }

    .contact-teaser:hover .contact-teaser__title {
        color: var(--color-primary);
    }

    .contact-teaser__body .contact-teaser__content div > a:hover {
        color: var(--color-primary);
    }
}