.slope {
    display:inline-block;
}
.slope-easy {
    background-color: var(--color-primary);
    border-radius: 50%;
    width: calc(18rem/16);
    height: calc(18rem/16);
}
.slope-moderate {
    background-color: var(--color-danger);
    transform: rotate(45deg);
    width: calc(14rem/16);
    height: calc(14rem/16);
}
.slope-difficult {
    background-color: #000;
    width: calc(16rem/16);
    height: calc(16rem/16);
}