.region-teaser__embed:before {
    padding-top: calc( 351 / 286 * 100%);
    @media screen and (max-width: 767px) {
        padding-top: 70%;
    }
}
.region-teaser {
    position:relative;
    cursor:pointer;
    box-shadow: 0 calc(28rem/16) calc(40rem/16) 0 rgba(35,59,72,0.3);
}
.region-teaser__body {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    padding: calc(18rem/16) calc(50rem/16);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 9;
}
.region-teaser__title {
    font-size: calc(16rem/16);
    text-transform: uppercase;
    text-shadow: 0 calc(2rem/16) calc(30rem/16) rgba(0,0,0,0.4);
    text-align:center;
    font-family: var(--font-default-bold);
    color: #fff;
    transition: 0.5s opacity 0.3s ease;
}
.region-teaser .region-teaser__inner:before {
    content:'';
    position:absolute;
    display:block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 calc(100rem/16) calc(75rem/16);
    border-color: transparent transparent var(--color-primary) transparent;
    z-index: 2;
    bottom:0;
    right:0;
    transform-origin: right bottom;
    transition: transform 0.4s ease, z-index 0.8s 0.001ms ease;
    opacity:0.8;
}
.region-teaser--gastein .region-teaser__inner:before {
    border-color: transparent transparent var(--color-gastein) transparent;
}
.region-teaser--sportwelt .region-teaser__inner:before {
    border-color: transparent transparent var(--color-sbg-sportwelt) transparent;
}
.region-teaser--schladming .region-teaser__inner:before {
    border-color: transparent transparent var(--color-schladming-dachstein) transparent;
}
.region-teaser--grossarltal .region-teaser__inner:before {
    border-color: transparent transparent var(--color-grossarltal) transparent;
}
.region-teaser--hochkoenig .region-teaser__inner:before {
    border-color: transparent transparent var(--color-hochkoenig) transparent;
}
.region-teaser:after {
    content:'';
    background-color: #182B35;
    opacity: 0.2;
    position: absolute;
    left:0;
    right:0;
    width:100%;
    height:100%;
    z-index: 2;
    transition: opacity 0.7s cubic-bezier(0.4, 0, 0.3, 1);
    pointer-events: none;
    top:0;
    @media screen and (max-width: 767px) {
        background: linear-gradient(180deg, rgba(47,66,80,0) 0%, rgba(47,66,80,0.7) 100%);
        opacity:1;
        z-index: 1;
    }
}
.region-teaser__item {
    @media screen and (max-width: 767px) {
        margin-top: .25rem;
    }
}
.region-teaser__item:nth-of-type(odd):hover .region-teaser__inner {
    @media screen and (min-width: 768px) {
        transform: scale(1.4) translateY(0);
        z-index: 3;
        position: relative;
    }
    @media screen and (min-width: 767px) and (max-width: 1580px) {
        transform: scale(1.2) translateY(0);
    }
}
.region-teaser__item:nth-of-type(even):hover .region-teaser__inner {
    @media screen and (min-width: 768px) {
        transform: scale(1.4)  translateY(0);
        z-index: 3;
        position: relative;
    }
    @media screen and (min-width: 767px) and (max-width: 1580px) {
        transform: scale(1.1) translateY(0);
    }
}
@media screen and (min-width: 768px) {
    .region-teaser__inner {
        overflow-y:hidden;
        transition: transform 0.4s cubic-bezier(0.4, 0, 0.3, 1);
    }
    .region-teaser:hover .region-teaser__inner:before {
        z-index: 3;
    }
    .region-teaser__wrapper {
        padding-top: calc(50rem/16);
        display: grid;
        grid-template-columns: 20% 20% 20% 20% 20%;
    }
    .region-teaser__wrapper > .region-teaser__item:nth-child(1) {
        -ms-grid-column: 1;
    }
    .region-teaser__wrapper > .region-teaser__item:nth-child(2) {
        -ms-grid-column: 2;
    }
    .region-teaser__wrapper > .region-teaser__item:nth-child(3) {
        -ms-grid-column: 3;
    }
    .region-teaser__wrapper > .region-teaser__item:nth-child(4) {
        -ms-grid-column: 4;
    }
    .region-teaser__wrapper > .region-teaser__item:nth-child(5) {
        -ms-grid-column: 5;
    }
    .region-teaser__item {
        position: relative;
        z-index: 1;
        padding: calc(7rem/16);
        transition: 0.8s z-index 0.0001ms ease;
    }
    .region-teaser__item--transformed {
        margin-top: calc(80rem/16);
    }
    .region-teaser__wrapper:hover .region-teaser:after {
        opacity: 0.65;
    }
    .region-teaser__wrapper .region-teaser:hover:after {
        opacity: 0;
        transition: opacity 0.2s cubic-bezier(0.4, 0, 0.3, 1);
    }
    .region-teaser__item:hover {
        transition: z-index 0.001ms ease;
        z-index: 5;
    }
    .region-teaser:hover .region-teaser__title {
        opacity:0;
        transition: opacity 0.2s ease;
    }
    .region-teaser:hover .region-teaser__inner:before {
        transform: scale(0);
    }
    .region-teaser__item:nth-of-type(even) {
        transform: translateY(calc(50rem/16));
    }
    .region-teaser__hover-content {
        opacity:0;
        position: absolute;
        text-align: center;
        width: 100%;
        height: 100%;
        top:0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        z-index: 9;
        transform: translateY(calc(140rem/16)) scale(0.3);
        transition: opacity 0.8s ease, transform 0.7s ease;
    }
    .region-teaser:hover .region-teaser__hover-content {
        opacity:1;
        transform: translateY(0);
    }
    .region-teaser__hover-content-title {
        text-transform:uppercase;
        color:#fff;
        font-family: var(--font-default-bold);
        line-height: calc(95/76);
        font-size: calc( 22px + (50 - 22) * (100vw - 768px)/(1920 - 768));
        /* 22px = min-font-size, 50px = max-font-size, 768px = min-screen-size, 1920 = max-screen-size */
        text-shadow: 0 calc(2rem/16) calc(20rem/16) rgba(0,0,0,0.6);
        max-width: 200%;
    }
}