.webcam-overlay {
    position: fixed;
    top: calc(50rem/16);
    left: 0;
    right: 0;
    height: 100%;
    max-width: 100vw;
    @media screen and (max-width: 767px) {
        z-index: 999;
    }
    @media screen and (min-width: 992px) {
        height: calc(840rem/16);
        top: calc(90rem/16);
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
        top: calc(50rem/16);
    }
}
.webcam-overlay__close {
    width: 100%;
    background-color: var(--color-primary);
    color: #fff;
    position: fixed;
    z-index: 999;
    font-size: calc(16rem/16);
    cursor: pointer;
    @media screen and (min-width: 768px) {
        width: calc(100rem/16);
        position:absolute;
        height:100%;
        left:0;
        font-size: calc(24rem/16);
    }
}
.webcam-overlay__close-wrapper {
    font-family: var(--font-default-bold);
    padding: calc(10rem/16);
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media screen and (min-width: 768px) {
        padding: calc(25rem/16);
    }
}
.webcam-overlay__close-text {
    display: block;
    @media screen and (min-width: 768px) {
        min-width: calc(400rem/16);
        transform: rotate(270deg);
        text-align:center;
    }
}
.webcam-overlay__content {
    width: 100%;
    padding: calc(60rem/16) calc(15rem/16);
    background-color: #fff;
    @media screen and (min-width: 768px) {
        padding: calc(45rem/16) calc(140rem/16);
        height: 100%;
        width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        padding: calc(45rem/16);
    }
}