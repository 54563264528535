.overlay {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-top: 0;
    transform: translateX(-100%);
    transition: transform 250ms ease-out, visibility 0.001ms 250ms, opacity 250ms ease;
    background: white;
    z-index: 10;
    overflow: hidden;
    will-change: transform;
    visibility: hidden;
    opacity: 0;
    color: var(--color-text-default);
}
.overlay.is-open {
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
    transition: transform 250ms ease-out, visibility 0.001ms, opacity 250ms ease;
}
.overlay__content {
    flex: auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.overlay__container {
    width: 100%;
    max-width: 91.25rem;
    margin: 0 auto;
    padding-left: calc(20rem / 16);
    padding-right: calc(20rem / 16);
    @media screen and (min-width: 768px) {
        padding-left: calc(80rem/16);
    }
}

@media screen and (min-width: 768px) {
    .overlay {
        box-shadow: 4px 4px 27px rgba(0, 0, 0, 0.05);
    }
    .overlay--left {
        right: auto;
        width: calc(800rem / 16);
        transform: translateX(-100%);
    }
    .overlay--right {
        right:0;
        left:auto;
        transform: translateX(100%);
    }
}
