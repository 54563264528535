.total-sum__wrapper {
    position: fixed;
    bottom: 0;
    background: var(--color-lightest-grey);
    width: 100%;
    left: 0;
    -webkit-box-shadow: 0px -7px 54px -17px rgba(97,97,97,0.65);
    -moz-box-shadow: 0px -7px 54px -17px rgba(97,97,97,0.65);
    box-shadow: 0px -7px 54px -17px rgba(97,97,97,0.55);

    z-index: 10;

    background-color: var(--color-primary);
    color: white;
}

.ticket-calculator__ajax-form-wrapper {
    min-height: 75vh;
}


.ticket-calculator .number-spinner__input {
    margin-right: -12px;
}
@media screen and (max-width: 767px) {
    .ticket-calculator .number-spinner__input {
        margin-right: 0;
    }
}

.ticket-item__teaser-inner {
    text-align: left;
}

.ticket-calculator select.form-control {
    white-space: break-spaces;
    padding-right: 2rem;
}

.ticket-calculator__img-teaser img {
    height: 100%;
    object-fit: cover;
}

.ticket-calculator__img-teaser .simple-img-teaser__embed {
    height: 100%;
}

.numberspinner-is-disabled {
    opacity: 0.2;
    pointer-events: none;
}

.ticket-item__title-date {
    font-family: var(--font-default);
}