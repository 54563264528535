.weather-teaser {
    position: relative;
    color: var(--color-white);
    height: 100%;
}
.weather-teaser.has-button {
    cursor: pointer;
}
.weather-teaser:before {
    background: rgba(5,30,45,0.65);
    content:'';
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 0;
}
.weather-teaser__body {
    height: 100%;
}
.weather-teaser__bg-image {
    height: 100%;
    background-position: center;
    background-size: cover;
}
.weather-teaser.has-button .weather-teaser__body {
    padding-bottom: calc(127rem/16);

    @media screen and (max-width: 1199px) {
        padding-bottom: calc(67rem/16);
    }
}
.weather-teaser--small.has-button .weather-teaser__body {
    padding-bottom: calc(47rem/16);
    @media screen and (max-width: 1199px) {
        padding-bottom: calc(47rem/16);
    }
}
.weather-teaser__day {
    width: 100%;
    height: 100%;
    padding: calc(50rem/16);

    @media screen and (max-width: 1199px) {
        padding: calc(20rem/16);
    }

    @media screen and (min-width: 1200px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.weather-teaser.has-button .weather-teaser__day {
    padding: calc(15rem/16);
}
.weather-teaser--small .weather-teaser__day {
    padding: calc(30rem/16) calc(15rem/16);
}
.weather-teaser__day.weather-teaser__current-day {
    border-bottom: 1px solid #547283;
    width: 100%;
}
.weather-teaser__day.has-border-left {
    border-left: 1px solid #547283;
}

.weather-teaser__title {
    font-size: calc(30rem/16);
    font-family: var(--font-default-bold);
    margin-bottom: calc(50rem/16);
    text-align: center;

    @media screen and (max-width: 767px) {
        font-size: calc(22rem/16);
        margin-bottom: calc(20rem/16);
    }
}
.weather-teaser.has-button .weather-teaser__title {
    margin-bottom: calc(35rem/16);

    @media screen and (max-width: 1199px) {
        margin-bottom: calc(15rem/16);
    }
}

.weather-teaser__subtitle {
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        margin-bottom: calc(5rem/16);
    }
}

.weather-teaser__region {
    font-size: calc(26rem/16);
    font-family: var(--font-default-bold);

    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
    }
}

.weather-teaser__link-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(50rem/16);
    background-color: var(--color-primary);
    cursor: pointer;
    transition: background-color 0.2s ease;

    @media screen and (max-width: 1199px) {
        padding: calc(20rem/16);
    }
}
.weather-teaser--small .weather-teaser__link-wrapper {
    padding: calc(10rem/16);
}

.weather-teaser__link {
    position: relative;
}
.weather-teaser__link:after {
    content: var(--icon-arrow-long);
    font-family: iconfont;
    position: absolute;
    top: 0;
    right: -110px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.weather-teaser__icon-big {
    font-size: calc(160rem/16);

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        font-size: calc(90rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(70rem/16);
    }
}
.weather-teaser__icon-medium {
    font-size: calc(70rem/16);

    @media screen and (max-width: 1199px) {
        font-size: calc(50rem/16);
    }
}

.weather-teaser__max,
.weather-teaser__min {
    position: relative;
    margin: calc(13rem/16) 0;
}

/* hover effects */
@media screen and (min-width: 1200px) {
    .weather-teaser.has-button:hover .weather-teaser__link-wrapper {
        background-color: var(--color-primary-dark);
    }
    .weather-teaser.has-button:hover .weather-teaser__link-wrapper .weather-teaser__link:after {
         transform: translateX(20px);
    }
}