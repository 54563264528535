.ticket-contingent {
    width: 26.5rem;
    display:block;
    max-width: 100%;
    margin: 0 auto;
    background-color: #216588;
    box-shadow: 0 0.125rem 0.5625rem rgba(0,0,0,.5);
    position: relative;
    margin-top: calc(70rem/16);
    border: 1px solid var(--color-light-grey);
    cursor: pointer;
    transition: background-color 0.2s ease;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}
.ticket-contingent__inner {
    padding: calc(60rem/16) 3rem 2rem;
    @media screen and (max-width: 767px) {
        padding: calc(50rem/16) calc(20rem/16) calc(20rem/16);
    }
}
.ticket-contingent__footer {
    overflow: hidden;
}
.ticket-contingent__footer-link {
    background-color: var(--color-primary);
    color:#fff;
    display:block;
    text-align: center;
    padding: calc(15rem/16);
    font-family: var(--font-default-bold);
    transition: background-color 0.2s ease, color 0.2s ease;
}
.ticket-contingent__footer-link:hover {
    background-color:#fff;
    color: var(--color-primary);
}
.ticket-contingent__counter {
    font-family: var(--font-default-bold);
    line-height: 1;
    text-align: center;
    font-size: 5rem;
    color: #fff;
    text-shadow: 0 0.125rem 0.5625rem rgba(0,0,0,.6);
    @media screen and (max-width: 767px) {
        font-size: calc(40rem/16);
    }

}
.ticket-contingent__counter-text {
    font-size: calc(40rem/16);
    color:#fff;
    margin-top: 1.25rem;
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    line-height:1;
    text-align:center;
    border-top: 2px dashed #fff;
    padding-top: calc(20rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
    }
}
.ticket-contingent__icon {
    color: #fff;
    transition: background-color 0.2s ease;
    margin-bottom: .625rem;
    font-size: 4.0625rem;
    text-align: center;
    position: absolute;
    background: var(--color-primary);
    border-radius: 50%;
    padding: calc(20rem/16);
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 767px) {
        font-size: calc(40rem/16);
    }
}
.ticket-contingent__pre-title {
    text-align:center;
    color:#fff;
}