:root {
    --live-console-width: calc(178rem / 16);
}
@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}
@keyframes showLiveItem {
    0% {
        opacity:0;
        transform: translateX(-100%) scale(0.8);
    }
    80% {
        opacity:1;
        transform: translateX(105%) scale(1.04);
    }
    100% {
        opacity:1;
        transform: translateX(100%) scale(1);
    }
}

.live-console {
    @media screen and (min-width: 768px) {
        position: absolute;
        left: calc(-160rem/16);
        top: calc(20rem/16);
        color: var(--color-primary);
        width: var(--live-console-width);
        display: flex;
        align-items: center;
        z-index:2;
    }
}
.live-console__list {
    padding-left: calc(30rem/16);
    padding-top: calc(12rem/16);
    @media screen and (max-width: 767px) {
        padding-left: 0;
        padding-top:0;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}
.live-console__list-item {
    position: relative;
    opacity: 0;
    margin-bottom: calc(25rem/16);
    text-align: center;
    display:flex;
    flex-direction: column;
    @media screen and (max-width: 767px) {
        margin-bottom: calc(20rem/16);
        opacity:1;
        transform: translateX(0);
        margin-right: calc(20rem/16);
        margin-left: calc(20rem/16);
        justify-content: center;
    }
}
.live-console__list-item__btn {
    display:inline-block;
    text-align:center;
}
@media screen and (min-width: 768px) {
    .is-in-viewport .live-console__list-item:nth-of-type(1) {
        animation: 0.5s showLiveItem 0.3s ease-out forwards;
    }
    .is-in-viewport .live-console__list-item:nth-of-type(2) {
        animation: 0.5s showLiveItem  0.5s ease-out forwards;
    }
    .is-in-viewport .live-console__list-item:nth-of-type(3) {
        animation: 0.5s showLiveItem 0.7s ease-out forwards;
    }
    .is-in-viewport .live-console__list-item:nth-of-type(4) {
        animation: 0.5s showLiveItem 0.9s ease-out forwards;
    }

    .is-in-viewport .live-console__list-item:before {
        content: '';
        display: block;
        position: absolute;
        background-color: var(--color-primary-dark);
        border-radius: 50%;
        width: calc(80rem/16);
        height: calc(80rem/16);
        top: 50%;
        left: 50%;
        opacity:0;
        transform-origin:left;
        transform: scale(0) translateX(-50%) translateY(-50%);
        animation: liveItemHoverBgBack .2s forwards;
        box-shadow: 0 .625rem 3.125rem 0 rgba(5,30,45,.6);
        transition: background-color 0.6s ease-in-out;
    }
    .is-in-viewport .live-console__list-item:hover:before {
        animation: liveItemHoverBg .45s forwards;
        background-color: var(--color-primary-transparent);
    }
    .is-in-viewport .live-console__list-item:hover .live-console__list-item__text {
        color: #ffffff;
        transform: scale(1.1) translateY(calc(-5rem/16));
    }
    .is-in-viewport .live-console__list-item:hover .live-console__list-item__icon {
        color: #ffffff;
        transform: scale(0.8) translateY(calc(5rem/16));
    }
    .is-in-viewport .live-console__list-item:hover .live-console__percentage-circle .circle {
        stroke: rgba(255, 255, 255, 0.4);
    }
    .is-in-viewport .live-console__list-item:hover .live-console__percentage-circle .circle-active {
        stroke: #fff;
    }
}

.live-console__list-item__text {
    display: block;
    font-size: calc(20rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(25/20);
    text-align: center;
    position: relative;
    transition: transform 0.2s ease-in-out;
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
        line-height: calc(8rem/16);
    }
}

.live-console__list-item__icon {
    font-size: calc(40rem/16);
    transition: transform 0.25s ease-in-out;
}

@keyframes liveItemHoverBg {
    0% {
        opacity:0;
        transform: scale(0) translateX(-50%) translateY(-50%);
    }
    80% {
        opacity:1;
        transform: scale(1.05) translateX(-50%) translateY(-50%);
    }
    100% {
        opacity:1;
        transform: scale(1) translateX(-50%) translateY(-50%);
    }
}

@keyframes liveItemHoverBgBack {
    0% {
        opacity:1;
        transform: scale(1) translateX(-50%) translateY(-50%);
    }
    100% {
        opacity:0;
        transform: scale(0) translateX(-50%) translateY(-50%);
    }
}
.live-console__percentage-circle  {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    display:block;
    width: calc(70rem/16);
    @media screen and (max-width: 767px) {
        width: calc(40rem/16);
    }
}

.live-console__percentage-circle .circle {
    stroke: rgba(0, 128, 203, 0.4);
    fill: none;
    stroke-width: calc(2rem/16);
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
    @media screen and (max-width: 767px) {
        stroke: rgba(255, 255, 255, 0.4);
    }
}
.live-console__percentage-circle .circle-active {
    stroke: var(--color-primary);
    fill: none;
    stroke-width: calc(2rem/16);
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
    @media screen and (max-width: 767px) {
        stroke: #ffffff;
    }
}
.live-console__percentage-circle-wrapper {
    height: calc(70rem/16);
    padding-top: calc(15rem/16);
    @media screen and (max-width: 767px) {
        height: calc(70rem/16);
        padding-top: calc(10rem/16);
        justify-content: initial;
    }
}
.live-console__percentage-circle-wrapper .live-console__list-item__icon {
    font-size: calc(35rem/16);
    @media screen and (max-width: 767px) {
        font-size: 1rem;
        margin-top: calc(17rem/16);
    }
}
.live-console__list-item__icon.icon-facilities {
    font-size: 3rem;
    @media screen and (max-width: 767px) {
        font-size: 2rem;
    }
}

/* live console hero */
.live-console.live-console--hero {
    color: #fff;
    @media screen and (min-width: 768px) {
        left: 0;
        top: 0;
        height: 100%;
    }
}
.live-console.live-console--hero .live-console__list-item {
    filter: drop-shadow(0 2px 15px rgba(0,0,0,0.8));
}
.live-console.live-console--hero .live-console__percentage-circle .circle {
    stroke: rgba(255, 255, 255, 0.4);
}
.live-console.live-console--hero .live-console__percentage-circle .circle-active {
    stroke: #fff;
}


/* live console mobile sticky */
.live-console {
    @media screen and (max-width: 767px) {
        background-color:var(--color-primary);
        color: #ffffff;
        height: 55px;
        position: fixed;
        bottom: 0;
        top: unset;
        left: 0;
        right:0;
        width: 100%;
        z-index: 90;
    }
}
.live-console__list{
    @media screen and (max-width: 767px) {
        margin-top: 0;
        align-items: center;
        height: 55px;
    }
}
.live-console__list-item {
    @media screen and (max-width: 767px) {
        filter: none;
        margin: 0;
        min-width: 25%;
    }
}
.live-console__list-item:not(:first-child):before {
    @media screen and (max-width: 767px) {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: calc(40rem/16);
        background-color: #ffffff;
    }
}
.live-console__list-item__icon {
    @media screen and (max-width: 767px) {
        font-size: calc(24rem/16);
    }
}