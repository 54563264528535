.teaser-slider__arrow {
    background-color: #fff;
    font-size: 1rem;
    color:var(--color-primary);
    width: calc(90rem/16);
    height: calc(90rem/16);
    line-height:1;
    outline: none;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease-in-out, color 0.2s ease-in-out;
    pointer-events: all;
    box-shadow: 0 calc(28rem/16) calc(40rem/16) 0 rgba(35,59,72,0.3);
    @media screen and (max-width: 1580px) {
        width: calc(55rem/16);
        height: calc(55rem/16);
    }
}

.teaser-slider__arrow-small {
    width: calc(55rem/16);
    height: calc(55rem/16);
}

.teaser-slider__arrow:not(.slick-disabled):hover {
    background-color: var(--color-primary);
    color:#fff;
}
.teaser-slider__arrow.slick-disabled {
    color: var(--color-primary-light);
    cursor:unset;
}
.teaser-slider .slick-list {
    padding-bottom: calc(60rem/16);
    @media screen and (max-width: 767px) {
        padding-bottom: calc(70rem/16);
    }
}
.teaser-slider__arrow.arrow-prev {
    position: absolute;
    left: calc(15rem/16);
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 2;
    @media screen and (max-width: 767px) {
        top: initial;
        bottom:0;
        transform: translateX(-50%);
        left: 40%;
        font-size: calc(11rem/16);
    }
}
.teaser-slider__arrow.arrow-next {
    position: absolute;
    right: calc(15rem/16);
    top: 50%;
    transform: translateY(-50%) translateX(50%);
    z-index: 2;
    @media screen and (max-width: 767px) {
        top: initial;
        bottom:0;
        transform: translateX(50%);
        right: 40%;
        font-size: calc(11rem/16);
    }
}
.blogger-teaser-slider .teaser-slider__arrow.arrow-next, .blogger-teaser-slider .teaser-slider__arrow.arrow-prev {
    top: 45%;
}