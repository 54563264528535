
.demi-infrastructure-sidebar__filters .demi-infrastructure-sidebar__box:not(:first-child){
  border-top: calc(1rem/16) solid #ddddd5;
}

.demi-infrastructure-sidebar__filters .form-group--search{
  margin-top: calc(20rem/16);
}

.demi-infrastructure-sidebar__head .demi-infrastructure-sidebar__collapse-icon{
  transform: rotate(180deg);
}

.demi-infrastructure-sidebar__head.collapsed .demi-infrastructure-sidebar__collapse-icon{
  transform: rotate(0deg);
}

.demi-infrastructure-sidebar__hl {
    font-family: var(--font-default-bold);
    margin-bottom:calc(20rem/16);
    font-size: calc(24rem/16);
}
.demi-infrastructure-sidebar__hl:after {
    content: "";
    height: .25rem;
    background-color: var(--color-secondary);
    display: block;
    bottom: 0;
    margin: calc(10rem/16) 0 calc(10rem/16);
    left: 0;
    width: 2.5rem;
    right: 0;
}
.demi-infrastructure-sidebar__filters {
    padding-bottom: calc(20rem/16);
    border: 1px solid  var(--color-primary);
}
.demi-infrastructure-sidebar__collapse-icon {
    transform:rotate(45deg);
    transition: transform 0.3s ease;
    font-size: calc(12rem/16);
}
.collapsed .demi-infrastructure-sidebar__collapse-icon {
    transform: rotate(0);
}
.demi-infrastructure-sidebar__head {
    background-color: var(--color-primary);
    color:#fff;
    padding: calc(10rem/16);
    cursor:pointer;
}
.demi-infrastructure-sidebar__box {
    margin-bottom: calc(20rem/16);
}
.demi-infrastructure-sidebar__body {
    padding: calc(10rem/16) calc(20rem/16);
}