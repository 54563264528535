.cc-tag-placeholder.datareporter-google-map,
.cc-tag-placeholder.datareporter-external-video,
.datareporter__custom-overlay {
    width: 100%;
    background-color: var(--color-primary-light);
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    place-content: center center;
}

.cc-tag-placeholder.datareporter-external-video .cc-tag-activate-btn,
.cc-tag-placeholder.datareporter-google-map .cc-tag-activate-btn {
    grid-row: 2 / span 1;
}

.cc-tag-placeholder.datareporter-external-video .cc-tag-activate-btn,
.cc-tag-placeholder.datareporter-google-map .cc-tag-activate-btn,
.datareporter__custom-overlay-button.datareporter__custom-overlay-button {
    justify-self: center;
    color: var(--color-secondary-contrast);
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    font-size: .875rem;
    border-radius: 100px;
    font-family: var(--font-default);
    padding: .5rem 2.25rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 4rem;
}

.cc-tag-placeholder.datareporter-external-video .cc-tag-info,
.cc-tag-placeholder.datareporter-google-map .cc-tag-info,
.datareporter__custom-overlay-text {
    font-size: .875rem;
    color: black;
    text-align: center;
    margin-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 4rem;
    white-space: break-spaces;
}

.datareporter__external-video {
    z-index: 1;
}

.datareporter__external-video-wrapper {
    position: relative;

    .vjs-big-play-button {
        display: none;
    }
}

.datareporter__custom-overlay-button {
    pointer-events: all;
}

.datareporter__external-video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 3;
}

.datareporter__external-video-wrapper--minheight {
    margin-top: 5rem;
}

.datareporter__external-video-wrapper--absolute-fill {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.datareporter__custom-overlay--minheight,
.datareporter__external-video-wrapper--minheight {
    min-height: 50vh;
}

.social-widget__wrapper {
    position: relative;
}

.social-widget__element {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.video-slider__item:has(.datareporter__external-video-overlay:not([hidden])) .video__play-btn {
    display: none;
}

@media screen and (max-width: 767px) {
    .datareporter__custom-overlay-text {
        font-size: .75rem !important;
    }

    .datareporter__custom-overlay-button {
        font-size: .65rem !important;
    }

    /* google map embed-responsive override */
    .embed-responsive.embed-responsive-2by1:has(.datareporter-google-map .cc-tag-activate-btn) {
        &::before {
            min-height: calc(450rem/16);
        }
    }

    /* youtube video slider overrides */
    .slick-slide:has(.video-slider__embed .datareporter__custom-overlay:not([hidden])) {
        min-height: calc(350rem/16);
        margin-top: -6rem;
        transition: 0.5s ease margin-top;
    }

    .slick-slide.slick-center:has(.video-slider__embed .datareporter__custom-overlay:not([hidden])) {
        min-height: calc(350rem/16);
        margin-top: 0rem;
    }

    .embed-responsive-16by9:has(.datareporter__custom-overlay:not([hidden])){
        &::before {
            min-height: calc(350rem/16);
        }
    }

    .lg-outer .lg-video:has(.datareporter__custom-overlay:not([hidden])) {
        min-height: calc(350rem/16);
        padding-bottom: calc(350rem/16);
    }

    .video-slider__embed:has(.datareporter__custom-overlay:not([hidden])) {
        &, &::before {
            min-height: calc(350rem/16);

        },

        .datareporter__custom-overlay-button {
            margin-left: 2rem;
            margin-right: 2rem;
        }

        .datareporter__custom-overlay-text {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
}

.lg-video .datareporter-external-video,
.lg-video .datareporter-external-video .lightbox-video__iframe {
    height: 100%;
}