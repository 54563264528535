.accommodation-request-counter {
    position: fixed;
    top: 50%;
    width: calc(90rem/16);
    height: calc(90rem/16);
    background-color: var(--color-primary);
    right: 0;
    color: #fff;
    border: none;
    border-radius: calc(5rem/16) 0 0 calc(5rem/16);
    box-shadow: 0 calc(12rem/16) calc(20rem/16) 0 rgba(35,59,72,0.3);
    @media screen and (max-width: 767px) {
        z-index: 999;
        width: calc(70rem/16);
        height: calc(60rem/16);
        bottom: calc(90rem/16);
        top: initial;
    }
}
.accommodation-request-counter__text {
    display: block;
    font-size: calc(12rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(10rem/16);
    }
}
.accommodation-request-counter__counter {
    background-color:#fff;
    color: var(--color-primary);
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    top: 0;
    box-shadow: 0 calc(12rem/16) calc(20rem/16) 0 rgba(35,59,72,0.3);
    transform: translate(-20%, -20%);
}
.accommodation-request-counter__icon {
    font-size: calc(30rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
        padding-left: calc(10rem/16);
    }
}