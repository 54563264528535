/*Margin Helper*/
.mb-30 {
    margin-bottom: calc(30rem/16);
}

/*Padding Helper*/


/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-underline {
    text-decoration: underline;
}

/*Text Color Helper*/
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-light-grey {color: var(--color-light-grey);}

/*Background Color Helper*/
.bg-gradient { background: var(--gradient);}
.bg-grey { background: var(--color-grey);}
.bg-light-blue { background: #F0F5FC;}

.overflow-x-scroll { overflow-x: scroll; }

.hidden-xs { 
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.visible-xs {
    display: none;
    @media screen and (max-width: 767px) {
        display: block;
    }
}

.font-bold {
    font-family:var(--font-default-bold);
}
.fz10 {
    font-size: calc(10rem/16);
}
.fz12 {
    font-size: calc(12rem/16);
}
.fz14 {
    font-size: calc(14rem/16);
}
.fz16 {
    font-size: calc(16rem/16);
}
.fz28 {
    font-size: calc(28rem/16);
}
.fz70 {
    font-size: calc(70rem/16);
}
.fz30-xs {
    @media screen and (max-width: 767px) {
        font-size: calc(30rem/16);
    }
}
.isCursor {
    cursor:pointer;
}
.position-static-md {
    @media screen and (max-width: 1199px) {
        position: static;
    }
}

.w-10 {width: 10%;}

.h-100 {
    height: 100%!important;
}
.text-shadow { text-shadow: 0 calc(2rem/16) calc(30rem/16) rgba(0,0,0,.9); }

.collapse-icon {
    transition: transform 0.2s ease;
}
.collapsed .collapse-icon {
    transform: rotate(270deg);
}
.collapse-icon {
    transform: rotate(90deg);
}
.overflow-x-hidden {
    overflow-x:hidden;
}
.h-md-100 {
    @media screen and (min-width: 768px) {
        height: 100%;
    }
}

.circle-border-icon {
    border: 2px solid #fff;
    border-radius: 50%;
    padding: calc(12rem/16);
    font-size: calc(23rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}

.content-disabled {
    pointer-events: none;
    opacity: 0.6;
}