.form-group-birthday-datepicker--competition {

}

.form-group-birthday-datepicker--competition .form-control {
    padding-left: calc(16rem/16);
    padding-right: calc(16rem/16);
}

.form-group-birthday-datepicker__legend {
    font-size: calc(18rem/16);
    transform: translateY(0) scale(.75) !important;
    top: calc(-16rem/16);
    left: calc(27rem/16);
    width: auto;
    background-color: white;
    padding-right: calc(20rem/16);
}