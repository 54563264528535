.demi-infrastructure-overview-content-block,
.demi-event-overview-content-block {
  margin-top: calc(40rem/16);

  @media screen and (min-width: 768px){
    margin-top: calc(70rem/16);
  }
}

.demi-infrastructure-overview-content-block .container .container, /* Es gibt leider container in container */
.demi-event-overview-content-block .container .container{
  padding: 0;
}

.row.row--has-demi-infrastructure-teaser,
.row.row--has-demi-event-teaser{
  margin-top: calc(-30rem/16);
  clear: both;
  
  @media screen and (max-width: 767px){
    margin-left: calc(-5rem/16);
    margin-right: calc(-5rem/16);
  }
}

.row.row--has-demi-infrastructure-teaser>[class*=" col"],
.row.row--has-demi-infrastructure-teaser>[class^=col],
.row.row--has-demi-event-teaser>[class*=" col"],
.row.row--has-demi-event-teaser>[class^=col]{
  margin-top: calc(30rem/16);

  @media screen and (max-width: 767px){
    padding-left: calc(5rem/16);
    padding-right: calc(5rem/16);
  }
}

.demi-infrastructure-paging,
.demi-event-paging{
  margin: calc(20rem/16) 0;
}



/* Teaser Grid */

.demi-infrastructure-teaser-grid,
.demi-event-teaser-grid{
  height: 100%;
}

.demi-infrastructure-teaser-grid__body,
.demi-event-teaser-grid__body{
  padding: calc(10rem/16) calc(5rem/16);

  @media screen and (min-width: 992px){
    padding: calc(20rem/16);
  }
  @media screen and (min-width: 768px){
    padding: calc(10rem/16);
  }
}

.demi-infrastructure-teaser-grid__title,
.demi-event-teaser-grid__title{
  text-transform: none;
  font-size: calc(17rem/16);
  margin-bottom: calc(10rem/16);

  @media screen and (min-width: 992px){
    font-size: calc(24rem/16);
  }

  @media screen and (min-width: 768px){
    font-size: calc(20rem/16);
    margin-bottom: calc(20rem/16);
  }
  
  @media screen and (max-width: 767px){
     word-break: break-word;
  }
}

.demi-infrastructure-teaser-grid__btn,
.demi-event-teaser-grid__btn{

  @media screen and (min-width: 768px) and (max-width: 991px){
    padding: calc(7rem/16) calc(12rem/16);
    font-size: calc(15rem/16);
  }
  @media screen and (max-width: 767px){
    font-size: calc(13rem/16);
    padding: calc(5rem/16) calc(10rem/16);
  }
}

.demi-event-teaser-grid__btn{
  margin-top: calc(15rem/16);
}


/* Teaser List */


.row.demi-infrastructure-teaser-list,
.row.demi-event-teaser-list{

  @media screen and (min-width: 768px){
    margin-left: 0;
    margin-right: 0;
  }
  
  @media screen and (max-width: 767px){
    margin-left: calc(-5rem/16);
    margin-right: calc(-5rem/16);

    padding: calc(15rem/16) calc(5rem/16);
  }
}

.row.demi-infrastructure-teaser-list>[class*=" col"],
.row.demi-infrastructure-teaser-list>[class^=col],
.row.demi-event-teaser-list>[class*=" col"],
.row.demi-event-teaser-list>[class^=col]{
  @media screen and (min-width: 768px){
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (max-width: 767px){
    padding-left: calc(5rem/16);
    padding-right: calc(5rem/16);
  }

}

.demi-event-teaser-list__body, .demi-infrastructure-teaser-list__body {
    padding: calc(10rem/16) calc(20rem/16);
    font-size: calc(16rem/16);
}

.demi-infrastructure-teaser-list__title,
.demi-event-teaser-list__title{
    text-transform:uppercase;
    font-size: calc(20rem/16);
    margin-bottom: calc(0rem/16);
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px){
     font-size: calc(24rem/16);
  }
}
.demi-event-teaser-list__title:after, .demi-infrastructure-teaser-list__title:after {
    content: "";
    height: .25rem;
    background-color: var(--color-secondary);
    display: block;
    width: 2.5rem;
    margin: calc(7rem/16) 0;
}
.demi-event-teaser-list, .demi-infrastructure-teaser-list {
    transition: background-color 0.2s ease;
}
.demi-event-teaser-list:hover, .demi-infrastructure-teaser-list:hover {
    background-color: rgba(0,0,0,0.04);
}
.demi-infrastructure-teaser-list__body p{
  margin: 0;
}

.demi-infrastructure-teaser-list__btn,
.demi-event-teaser-list__btn{
  margin-top: calc(15rem/16);

  @media screen and (min-width: 768px) and (max-width: 991px) {
    padding: calc(5rem/16) calc(15rem/16);
    font-size: calc(15rem/16);
  }
  @media screen and (max-width: 767px){
    font-size: calc(13rem/16);
    padding: calc(5rem/16) calc(10rem/16);
  }
}