.avalanche-item {
    background-color: #fff;
    box-shadow: 0 20px 40px 0 rgba(35,59,72,0.1);
    display: flex;
    flex-direction: column-reverse;

    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: 1fr calc(300rem/16);
    }
}
.avalanche-item__content {
    padding: calc(32rem/16) calc(120rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(32rem/16) calc(32rem/16);
    }
}
.avalanche-item__top-title {
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    text-transform:uppercase;
    margin-bottom: calc(16rem/16);
    line-height: 1;
}
.avalanche-item__title {
    font-size: calc(30rem/16);
    font-family: var(--font-default-bold);
    line-height: 1;

    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
    }
}
.avalanche-item__title:after {
    content: '';
    background-color: var(--color-primary-light);
    height: 1px;
    width: calc(161rem/16);
    display: block;
    margin-top: calc(31rem/16);
    margin-bottom: calc(16rem/16);

    @media screen and (max-width: 767px) {
        margin-top: calc(16rem/16);
        margin-bottom: calc(10rem/16);
    }
}
.avalanche-item__wysiwyg {
    font-size: calc(16rem/16);
}
.avalanche-item__img-wrapper--1 {
    background-color: var(--color-booking);
}
.avalanche-item__img-wrapper--2 {
    background-color: #e9e98a;
}
.avalanche-item__img-wrapper--3 {
    background-color: #f3be6e;
}
.avalanche-item__img-wrapper--4 {
    background-color: #dd4b4b;
}
.avalanche-item__img-wrapper--5 {
    background-color: #c53d3d;
}
.avalanche-item__img {
    width: calc(190rem/16);
    @media screen and (max-width: 767px) {
        width: calc(100rem/16);
    }
}
.avalanche-item__img--4, .avalanche-item__img--5, .avalanche-item__img--3{
    width: calc(220rem/16);
    @media screen and (max-width: 767px) {
        width: calc(120rem/16);
    }
}
.avalanche-item__embed:before {
    padding-top: 100%;
    @media screen and (max-width: 767px) {
        padding-top: 40%;
    }
}