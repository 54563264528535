.highlight-media {
    position:absolute;
    right:calc(50rem/16);
    width: calc(457rem/16);
    bottom:0;
    z-index: 9;
    @media screen and (min-width: 1580px) {
        right: calc((100vw - 1460px) /2);
    }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        width: calc(300rem/16);
    }
    @media screen and (max-width: 767px) {
        position: relative;
        width: 100%;
        right:0;
        padding-left: calc(15rem/16);
        padding-right: calc(15rem/16);
        margin-top: calc(-20rem/16);
    }
}
.highlight-media--social-media-post {
    width: calc(320rem/16);
}
.highlight-media__inner {
    position: relative;
    box-shadow: 0 calc(42rem/16) calc(50rem/16) 0 rgba(5,30,45,0.3);
}
.highlight-media__user {
    width: calc(95rem/16);
    height: calc(95rem/16);
    position:absolute;
    top:0;
    right:0;
    transform: translateX(33%) translateY(-35%);
    z-index: 10;
    box-shadow: 0 calc(13rem/16) calc(18rem/16) 0 rgba(51,51,51,0.2);
    border-radius: 50%;
    @media screen and (max-width: 767px) {
        width: calc(75rem/16);
        height: calc(75rem/16);
        transform: translateX(0) translateY(-40%);
    }
}
.highlight-media__user__img-wrapper {
    border-radius: 50%;
    overflow:hidden;
}
.highlight-media__user__img-wrapper:after {
    content:'';
    position: absolute;
    height:100%;
    width: 100%;
    top:0;
    border-radius: 50%;
    margin: 0 auto;
    right: 0;
    left:0;
    border: calc(5rem/16) solid #fff;
}
.highlight-media__text {
    font-size: calc(26rem/16);
    line-height: calc(57/26);
    color:#fff;
    position: absolute;
    z-index: 11;
    bottom:0;
    padding-left: calc(15rem/16);
    font-family: var(--font-default-bold);
    transition: opacity 0.3s ease;
    //pointer-events:none;
    margin-bottom: calc(20rem/16);
    text-shadow: calc(10rem/16) calc(8rem/16) calc(26rem/16) rgba(0,0,0,1);
    @media screen and (max-width: 1199px) {
        font-size: calc(14rem/16);
    }
}
.highlight-media .vjs-poster{
    background-size: cover;
}
.highlight-media__video-wrapper.is-affix {
    position:fixed;
    bottom: calc(10rem/16);
    right: calc(10rem/16);
    width: calc(350rem/16);
    z-index: 999;
    animation: fadeIn 0.8s ease-in-out forwards;
}
.highlight-media.is-playing .highlight-media__text {
    opacity:0;
}
.highlight-media__video-wrapper .vjs-has-started .vjs-control-bar {
    opacity:0;
}
.highlight-media__video-wrapper:hover .vjs-control-bar {
    opacity:1;
}
.highlight-media__video-wrapper .video-js .vjs-big-play-button {
    background-color: transparent;
    color: #ffffff;
    box-shadow: none;
    pointer-events:none;
    filter: drop-shadow(0 0 calc(15rem/16) rgba(0,0,0,.3));
}
.highlight-media__video-wrapper .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    color: #ffffff;
}

.highlight-media__hide-media {
    opacity:0;
    position:absolute;
    right:0;
    top:0;
    z-index: 10;
    color:#fff;
    background: rgba(0, 47, 75, 0.7);
    box-shadow: none;
    border: none;
    outline: none;
    font-size: calc(14rem/16);
    width: calc(30rem/16);
    height: calc(30rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease;
}
.highlight-media__video-wrapper.is-affix:hover .highlight-media__hide-media {
    opacity:1;
}
@keyframes fadeIn {
    0% {
        opacity:0;
        transform: translateY(100%);
    }
    100% {
        opacity:1;
        transform: translateY(0);
    }
}

/* hover states */
@media screen and (min-width: 768px) {
    .highlight-media__video-wrapper .video-js .vjs-big-play-button:focus,
    .highlight-media__video-wrapper .video-js:hover .vjs-big-play-button {
        background-color: transparent;
    }
}