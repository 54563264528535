#demi-event-content .breadcrumb{
  max-width: calc(1410rem/16);
  margin: 0 auto;
  margin-bottom: calc(25rem/16);
}


.demi-infrastructure-detail-content-block,
.demi-event-detail-content-block{
  margin-top: calc(40rem/16);

  @media screen and (min-width: 768px){
    margin-top: calc(50rem/16);
  }
}
.demi-event-detail-content-block--contact .wysiwyg {
    overflow-x:hidden;
}
.demi-infrastructure-detail-content-block h1,
.demi-event-detail-content-block h1{
  margin-bottom: calc(15rem/16);

  @media screen and (min-width: 768px){
    margin-bottom: calc(30rem/16);
  }
}
.demi-infrastructure-detail-content-block h1:before,
.demi-event-detail-content-block h1:after {
    content: "";
    height: .25rem;
    background-color: var(--color-secondary);
    display: block;
    bottom: 0;
    margin: 1.25rem 0;
    left: 0;
    width: 2.5rem;
    right: 0;
}
.demi-infrastructure-detail-content-block h2,
.demi-event-detail-content-block h2{
  text-transform: none;
    font-size: calc(28rem/16);
}
.demi-infrastructure-detail-content-block h2:after,
.demi-event-detail-content-block h2:after {
    content: "";
    height: .25rem;
    background-color: var(--color-secondary);
    display: block;
    bottom: 0;
    margin: 1.25rem 0;
    left: 0;
    width: 2.5rem;
    right: 0;
}
.demi-infrastructure-detail-content-block--contact .row{
  @media screen and (max-width: 767px){
     margin-top: calc(-20rem/16);
  }
}

.demi-infrastructure-detail-content-block--contact .row>[class*=" col"],
.demi-infrastructure-detail-content-block--contact .row>[class^=col]{
  @media screen and (max-width: 767px){
     margin-top: calc(20rem/16);
  }
}


/* Accordion Collapse */

.demi-infrastructure-detail-content-block--gallery figure,
.demi-event-detail-content-block--gallery figure{
  margin: 0;
}

.demi-infrastructure-detail-content-block--times .card-header,
.demi-event-detail-content-block--times .card-header{
  padding: 0;
}

.demi-infrastructure-detail-content-block--times .card-header a,
.demi-event-detail-content-block--times .card-header a{
  display: block;
  padding: calc(10rem/16) calc(30rem/16) calc(10rem/16) calc(15rem/16);

  position: relative;
  font-size: calc(15rem/16);

  color: var(--color-default);
  background-color: #f5f5f5;

  @media screen and (min-width: 768px){
    padding: calc(15rem/16) calc(45rem/16) calc(15rem/16) calc(30rem/16);
    font-size: calc(19rem/16);
  }
}

.demi-infrastructure-detail-content-block--times .card-header a.collapsed,
.demi-event-detail-content-block--times .card-header a.collapsed{
  background-color: #fff;
  color: var(--color-default);
}

.demi-infrastructure-detail-content-block--times .card-header a .icon,
.demi-event-detail-content-block--times .card-header a .icon{
  font-size: calc(7rem/16);
  position: absolute;
  right: calc(15rem/16);
  top: calc(16rem/16);
  transform: rotate(180deg);

  @media screen and (min-width: 768px){
    right: calc(20rem/16);
    top: calc(22rem/16);
    font-size: calc(8rem/16);
  }
}
.demi-infrastructure-detail-content-block--times .card-header a.collapsed .icon,
.demi-event-detail-content-block--times .card-header a.collapsed .icon{
  transform: rotate(0deg);
}

/* Slider */

.demi-infrastructure-detail-content-block--gallery .slick-dots,
.demi-event-detail-content-block--gallery .slick-dots{
  display: none!important;
}

.demi-event-detail-content-block--gallery .btn.slick-arrow.slick-prev,
.demi-infrastructure-detail-content-block--gallery .btn.slick-arrow.slick-prev,
.demi-infrastructure-detail-content-block--gallery .btn.slick-arrow.slick-next,
.demi-event-detail-content-block--gallery .btn.slick-arrow.slick-next{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  padding: calc(15rem/16) calc(13rem/16);
  font-size: 20px;
}

.demi-event-detail-content-block--gallery .btn.slick-arrow.slick-prev,
.demi-infrastructure-detail-content-block--gallery .btn.slick-arrow.slick-prev{
  left: 0;
}

.demi-infrastructure-detail-content-block--gallery .btn.slick-arrow.slick-next,
.demi-event-detail-content-block--gallery .btn.slick-arrow.slick-next{
  right: 0;
}
