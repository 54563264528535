.form-errors {
    color: var(--color-danger);
}
.form-errors:empty {
    display: none;
}
.form-errors ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
ul.parsley-errors-list {
    color: var(--color-danger);
    font-size: calc(14rem/16);
    list-style-type: none;
    position: absolute;
    right: calc(25rem/16);
}
.form__custom-error-message {
    color: var(--color-danger);
    font-size: calc(14rem/16);
    text-align: right;
    margin-top: calc(16rem/16);
}
.parsley-single-error .filled ~ .filled {
    display: none;
}
.form-errors--text-left ul.parsley-errors-list {
    right: initial;
    left:0;
}
.form-control.has-error,
.form-group.has-error .form-control {
    border-color: var(--color-danger);
}