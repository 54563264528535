.modal .modal-content {
    border-radius: 0;
    box-shadow: 0 calc(20rem/16) calc(20rem/16) 0 rgba(35,59,72,1);
    padding: calc(40rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
.modal .modal-header .modal-title {
    font-family: var(--font-default-bold);
    text-transform: uppercase;
}
.modal .modal-header .close {
    font-size: calc(45rem/16);
    padding-top: 0;
}
.modal .modal-header .modal__close {
    position: absolute;
    top:0;
    right:0;
    font-size: calc(25rem/16);
    opacity:1;
    padding: calc(20rem/16);
    margin:0;
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
        font-size: calc(15rem/16);
    }
}
.modal__wysiwyg {
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}