.partner-logo-teaser {
    background-color: white;
    padding: 1rem;
    box-shadow: 0 0 calc(20rem/16) rgba(0,0,0,0.1);
    display: block;
}

@media screen and (min-width: 768px) {
    .partner-logo-teaser {
        padding: 1.5rem;
    }
}

.partner-logo-teaser__image {
    object-fit: contain;
}