.content-block + .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + .content-block {
    margin-top: calc(40rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(80rem/16);
    }
}
.content-block--no-footer-margin {
    margin-bottom: calc(-160rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(-30rem/16);
    }
}

.content-block + .content-block.has-anchor-offset,
.pimcore_area_content + .pimcore_area_content > .content-block.has-anchor-offset,
.content-block + .pimcore_area_content > .content-block.has-anchor-offset {
    margin-top: calc((var(--main-nav-height)* (-1) - 40rem/16) + 80rem/16);
    padding-top: calc(var(--main-nav-height) + 40rem/16);

    @media (max-width: 767px) {
        margin-top: calc((var(--main-nav-height)* (-1) - 20rem/16) + 40rem/16);
        padding-top: calc(var(--main-nav-height) + 20rem/16);
    }
}

.pimcore_area_content.pimcore_area_microsite-snowpark-facts + .pimcore_area_content.pimcore_area_microsite-snowpark-facts > .content-block {
    margin-top: calc(5rem/16);
    @media (max-width: 767px) {
        margin-top: calc(5rem/16);
    }
}


.has-anchor-offset {
    border-top: 1px solid transparent;
    margin-top: calc((var(--main-nav-height)* (-1) - 30rem/16));
    padding-top: calc(var(--main-nav-height) + 30rem/16);

    @media (max-width: 767px) {
        margin-top: calc((var(--main-nav-height)* (-1) - 20rem/16));
        padding-top: calc(var(--main-nav-height) + 20rem/16);
    }
}