.facts-item {
    border: calc(2rem/16) solid #fff;
    box-shadow: 0 calc(28rem/16) calc(40rem/16) 0 rgba(35,59,72,0.3);
    background-color: rgba(255,255,255,0.2);
    @media screen and (min-width: 768px) {
        min-height: calc(350rem/16);
        transition: background-color 0.3s ease;
    }
}
.facts-item__content {
    padding: calc(15rem/16);
    font-size: calc(16rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
        font-size: calc(15rem/16);
    }
}
@media screen and (min-width: 768px) {
    .facts-item:hover {
        background-color:#fff;
    }
}
.facts-item__icon {
    display: block;
    font-size: calc(35rem/16);
    text-align: center;
    margin-top: calc(10rem/16);
    margin-bottom: calc(10rem/16);
}
.facts-item__title {
    text-align: center;
}