/** @define arrange */

:root {
  --arrange-gutter-size: 10px;
}

/**
 * This component lets you lay out a row of cells in various ways. You can
 * specify whether a cell should be wide enough to fit its content, or take up
 * the remaining space in the row. It's also possible to give all cells an
 * equal width, and to control their vertical alignment.
 */

/**
 * 1. Protect against the component expanding beyond the confines of its
 *    container if properties affecting the box-model are applied to the
 *    component. Mainly necessary because of (5).
 * 2. Rely on table layout.
 * 3. Zero out the default spacing that might be on an element (e.g., `ul`).
 * 4. Make sure the component fills at least the full width of its parent.
 * 5. Reset the table-layout algorithm in case a component is nested.
 */

.arrange {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; /* 1 */
  display: table; /* 2 */
  margin: 0; /* 3 */
  min-width: 100%; /* 4 */
  padding: 0; /* 3 */
  table-layout: auto; /* 5 */

  /*outline: 1px solid blue;*/
}

/**
 * There are two possible types of child. `sizeFill` will expand to fill all
 * of the remaining space not filled by `sizeFit` elements.
 *
 * 1. Zero out any default spacing that might be on an element (e.g., `li`);
 *    Margin has no effect when coupled with `display: table-cell`.
 * 2. All cells are top-aligned by default
 */
.arrange-sizeFill.arrange-sizeFill,
.arrange-sizeFit.arrange-sizeFit {
  display: table-cell;
}

.arrange-sizeFill,
.arrange-sizeFit {
  display: table-cell;
  padding: 0; /* 1 */
  vertical-align: top; /* 2 */

  /*outline: 1px solid lightcoral;*/
}

/**
 * Make sure the main content block expands to fill the remaining space.
 */

.arrange-sizeFill {
  width: 100%;
  /*outline: 1px solid lightgreen;*/
}


/* Vertical alignment modifiers
   ========================================================================== */

.arrange--middle > .arrange-sizeFill,
.arrange--middle > .arrange-sizeFit {
  vertical-align: middle;
}