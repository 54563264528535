.ad-teaser{
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
}
.ad-teaser-list-area__list .ad-teaser{
    height: 100%;
    display: flex;
    flex-direction: column;
}
.ad-teaser-list-area__list .ad-teaser__body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.ad-teaser-list-area__list {
    display: flex;
    flex-direction: column;
}

.ad-teaser-list-area__list .ad-teaser__body {
    flex: 1;
}

.ad-teaser__title {
    font-size: calc(20rem/16);
    margin-bottom: calc(8rem/16);
    text-align:center;
    font-family: var(--font-default-bold);
}
.ad-teaser_link{
    display: flex;
    justify-content: center;
}
.ad-teaser__link-wrapper{
    text-align:center;
}
.ad-teaser__embed:before {
    padding-top: calc(270 / 330 * 100%);
}
.ad-teaser-list-area__list {
    @media screen and (max-width: 767px) {
        padding-bottom: calc(60rem/16);
    }
}
.ad-teaser-list__row{
    display: flex;
    justify-content: center;
}
.ad-teaser__body {
    padding: calc(13rem/16) calc(15rem/16) calc(30rem/16);
}
.ad-teaser__img-wrapper {
    position: relative;
}
.ad-teaser__tag {
    padding: calc(5rem/16) calc(11rem/16);
    font-size: calc(14rem/16);
    line-height: calc(19/14);
    background-color: var(--color-primary-light);
    position:absolute;
    right:0;
    transition: background-color 0.2s ease;
    bottom:0;
}
.ad-teaser__tag:hover {
    background-color: var(--color-primary);
    color:#fff;
}
.ad-teaser__ad-claim {
    background-color: var(--color-grey);
    color:#fff;
    font-size: calc(14rem/16);
    padding: calc(6rem/16) calc(11rem/16);
    font-family: var(--font-default-bold);
    position: absolute;
    top: calc(18rem/16);
}
.ad-teaser__content {
    font-size: 1rem;
    text-align:center;
    display: -webkit-box;
}
.ad-teaser__title > a:hover {
    color: var(--color-primary);
}

.ad-teaser__title > a::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0,0,0,0);
}
.ad-teaser__btn {
    margin-top: calc(20rem/16);
    text-align: center;
}
.ad-teaser__btn > .btn {
    display: inline-block;
    font-family: var(--font-default-bold);
    font-weight: 400;
    color: var(--color-text-default);
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 2px solid transparent;
    padding: 1rem 2.25rem;
    font-size: 1rem;
    line-height: 1;
    border-radius: 1.5625rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}
.ad-teaser__btn > .btn-primary {
    color: var(--color-primary-contrast);
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}
.ad-teaser__btn > .btn:hover {
    text-decoration: none;
}
.ad-teaser__btn > .btn-primary:hover {
    color: var(--color-primary-contrast);
    background-color: var(--color-primary-dark);
    border-color: var(--color-primary-dark);
}

.ad-teaser__embed:before {
    padding-top: calc( 335 / 335 * 100%);
}

.ad-teaser-slider .slick-slide > div {
    height: 100%
}

.ad-teaser-list-area__list .slick-track
{
    display: flex !important;
}

.ad-teaser-list-area__list .slick-slide
{
    height: inherit !important;
}