
.slider:not(.slick-initialized) > div + div {
    display: none;
}
.slick-slider--hide-arrows .slick-arrow {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}
.slick-slider--hide-dots .slick-dots {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}
.slick-slide>div {
    display: flex;
}

.slick-slider__arrow--primary-square {
    background-color: var(--color-primary);
    font-size: 1rem;
    color:#fff;
    width: calc(100rem/16);
    height: calc(100rem/16);
    line-height:1;
    outline: none;
    box-shadow: none;
    border: none;
    margin-left: calc(1rem/16);
    margin-right: calc(1rem/16);
    transition: background-color 0.3s ease-in-out;
    pointer-events: all;
    @media screen and (max-width: 767px) {
        width: calc(48rem/16);
        height: calc(48rem/16);
        font-size: calc(11rem/16);
    }
}
.slick-slider__arrow--primary-square:hover {
    background-color: var(--color-primary-dark);
}
.slick-slider__arrow--primary-square.slick-disabled {
    background-color: var(--color-primary-light);
    cursor: unset;
}