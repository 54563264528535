.video-slider {
    position: relative;
}
.video-slider .slick-slide.slick-current {
    transform: scale(1) translateX(0);
    transition: transform 0.8s ease;
}
.video-slider .slick-slide {
    width:var(--item-width);
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        width:var(--item-width-md);
    }
    @media screen and (max-width: 767px) {
        width:var(--item-width-xs);
    }
}
.video-slider .slick-slide:not(.slick-current) {
    transform: scale(0.7) translateX(14%);;
    transition: transform 0.8s ease;
}
.video-slider .slick-slide.slick-current + .slick-slide {
    transform: scale(0.7) translateX(-14%);;
}
.video-slider .slick-slide .video-slider__item:before {
    content:'';
    position:absolute;
    width:100%;
    background: #fff;
    height:100%;
    top:0;
    left:0;
    z-index: 1;
    opacity: 0.4;
    transition: opacity 0.4s ease;
}
.video-slider__item:after {
    content:'';
    position:absolute;
    pointer-events: none;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index: 1;
    opacity: 0.8;
    background: linear-gradient(180deg, rgba(149, 213, 255, 0), rgba(47, 66, 80, 0.78));
    @media screen and (max-width: 767px) {
        background: linear-gradient(180deg, rgba(47, 66, 80, 0.78), rgba(149, 213, 255, 0));
    }
}
.video-slider .slick-slide.slick-current .video-slider__item:before {
    opacity:0;
    pointer-events: none;
}

.video-slider__media-wrapper {
    overflow:visible;
}
.video-slider .slick-slide .video-slider__media-wrapper:before {
    content:'';
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    top:0;
    opacity:0;
    box-shadow: 0 12px 20px 0 rgba(35,59,72,0.3);
    transition: opacity 0.5s ease;
    z-index: -1;
}
.video-slider .slick-slide.slick-current .video-slider__media-wrapper:before {
    opacity:1;
    pointer-events: none;
}
.video-slider__media {
    pointer-events: all;
}
.video-slider__embed {
    padding-top: calc( 500 / 940 * 100%);
}
.video-slider__item {
    margin-top: calc(30rem/16);
    margin-bottom: calc(80rem/16);
    position: relative;
}
.video-slider__item-title {
    position: absolute;
    bottom:0;
    color:#fff;
    font-size: calc(22rem/16);
    padding: calc(15rem/16) calc(20rem/16);
    font-family: var(--font-default-bold);
    line-height: 1;
    z-index: 2;
    @media screen and (max-width: 767px) {
        top: 0;
        padding: calc(10rem/16);
        bottom: initial;
        font-size: calc(14rem/16);
    }
}
.video-slider__arrow-wrapper {
    position:absolute;
    right: calc((100vw - var(--item-width)) /2 - calc(10rem/16));
    bottom: var(--item-padding-bottom);
    transform: translateY(50%);
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        right: calc((100vw - var(--item-width-md)) /2 - calc(10rem/16));
    }
    @media screen and (max-width: 767px) {
        right: calc((100vw - var(--item-width-xs)) /2 - calc(10rem/16));
    }
}

.video-slider .slick-slide.slick-current .video__play-btn {
    opacity:1;
}
.video-slider .video__play-btn {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width:calc(90rem/16);
    height: calc(90rem/16);
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: #fff;
    transform-origin: center;
    transform: translate(-50%, -50%) scale(1) ;
    transition: transform 400ms ease;
    z-index: 99;
    box-shadow: 0 12px 20px 0 rgba(35,59,72,0.3);
    @media screen and (max-width: 767px) {
        width:calc(50rem/16);
        height: calc(50rem/16);
    }
}
.video-slider .video__play-btn .video-slider__play-btn-icon {
    font-size: calc(90rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(50rem/16);
    }
}
.video-slider .video__play-btn .video-slider__play-btn-icon:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    height: auto;
    width: auto;
    transition: color 400ms ease;
}

/* hover states */
@media screen and (min-width: 768px) {
    .video-slider__media-wrapper .video__play-btn:focus,
    .video-slider__media-wrapper:hover .video__play-btn {
        transform-origin: center;
        transform: translate(-50%, -50%) scale(1.4);
        transition: transform 400ms ease;
    }
}