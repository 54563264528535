.img-text-slide {
    color: #fff;
}
.img-text-slide__title {
    font-family: var(--font-default-bold);
    margin-bottom: calc(35rem/16);
    margin-top: calc(40rem/16);
    text-transform:uppercase;
    @media screen and (max-width: 767px) {
        font-size: calc(22rem/16);
        margin-top: 0;
    }
}
.img-text-slide__media {
    width: calc(60rem/16);
    margin-top: calc(20rem/16);
    @media screen and (max-width: 767px) {
        margin-top: 0;
    }
}