.facility-table {
    box-shadow: 0 50px 60px 0 rgba(5,30,45,0.1);
}
.facility-table__title {
    position: relative;
    display: inline-block;
    font-family: var(--font-default-bold);
    color: #ffffff;
    background-color: var(--color-primary);
    padding: calc(20rem/16) calc(35rem/16) calc(20rem/16) calc(25rem/16);
}
.facility-table__title:after {
    content: '';
    position: absolute;
    display: block;
    right: 0;
    bottom: 0;
    width: 0;
    height: 100%;
    border-style: solid;
    border-width: calc(67rem/16) 0 0 calc(40rem/16);
    transform: translateX(100%);
}
.facility-table__title.facility-table__title--hochkoenig { background-color: var(--color-hochkoenig);}
.facility-table__title.facility-table__title--grossarltal { background-color: var(--color-grossarltal);}
.facility-table__title.facility-table__title--schladming { background-color: var(--color-schladming-dachstein);}
.facility-table__title.facility-table__title--sportwelt { background-color: var(--color-sbg-sportwelt);}
.facility-table__title.facility-table__title--gastein { background-color: var(--color-gastein);}

.facility-table__title:after { border-color: transparent transparent transparent var(--color-primary);}
.facility-table__title.facility-table__title--hochkoenig:after { border-color: transparent transparent transparent var(--color-hochkoenig);}
.facility-table__title.facility-table__title--grossarltal:after { border-color: transparent transparent transparent var(--color-grossarltal);}
.facility-table__title.facility-table__title--schladming:after { border-color: transparent transparent transparent var(--color-schladming-dachstein);}
.facility-table__title.facility-table__title--sportwelt:after { border-color: transparent transparent transparent var(--color-sbg-sportwelt);}
.facility-table__title.facility-table__title--gastein:after { border-color: transparent transparent transparent var(--color-gastein);}

.facility-table__table {
    font-size: calc(16rem/16);
    background-color: #ffffff;
    margin-bottom: 0;
}

.facility-table__table thead th {
    background-color: var(--color-grey);
    color: #ffffff;
    border: none;
    padding: calc(20rem / 16);
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
    }
}

.facility-table__table td,
.facility-table__table th {
    vertical-align: middle;
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
        font-size: 1rem;
    }
}

.facility-table__type-icon {
    font-size: calc(30rem/16);
}
.facility-table__status-icon {
    font-size: calc(20rem/16);
}
.facility-table__info {
    background-color: var(--color-primary-light);
    padding: calc(20rem/16) calc(30rem/16);
    color: var(--color-text-default);
}