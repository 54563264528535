.anchor-nav__list {
    display:flex;
    justify-content:space-around;
    display: flex;
    height:100%;
    @media screen and (max-width: 767px) {
        display:inline-block;
        width:100%;
        overflow-x:auto;
        white-space:nowrap;
    }
}
.anchor-nav__item {
    font-size: calc(14rem/16);
    font-family: var(--font-default-bold);
    display:inline-flex;
    text-transform:uppercase;
    align-items: center;
    width:100%;
    position:relative;
    overflow:hidden;
    @media screen and (max-width: 767px) {
        display:inline;
        padding: calc(10rem/16);
    }
}
.anchor-nav__item:before {
    content: '';
    position: absolute;
    bottom: 0;
    height: calc(5rem/16);
    background: var(--color-primary-light);
    width: 100%;
    left: 0;
    z-index: 1;
}
.anchor-nav__item-link {
    padding: calc(10rem/16) calc(30rem/16);
    width:100%;
    text-align:center;
    @media screen and (max-width: 767px) {
        width: auto;
        padding: calc(10rem/16) 0;
        font-size: calc(13rem/16);
    }
}
.anchor-nav__item-link:before {
    content: '';
    position: absolute;
    bottom:0;
    height: calc(5rem/16);
    background: var(--color-primary);
    width: 100%;
    left: 0;
    @media screen and (min-width: 768px) {
        transform: translateX(-100%);
        transition: transform 0.3s ease;
        z-index: 1;
    }
    @media screen and (max-width: 767px) {
        opacity:0;
    }
}
.anchor-nav__item-link.active:before {
    @media screen and (min-width: 768px) {
        transform: translateX(0);
        transition: transform 0.03s ease;
    }
    @media screen and (max-width: 767px) {
        z-index: 99;
        opacity:1;
        transition: opacity 0.03s ease;
    }
}
.anchor-nav {
    transition: transform 0.2s ease;
    background-color:#fff;
    z-index: 99;
    height: calc(80rem/16);
}
.anchor-nav.is-affix {
    height: calc(40rem/16);
    transform: translateY(calc(90rem/16)) !important;
    opacity:1;
    @media screen and (max-width: 991px) {
        height: calc(34rem/16);
        transform: translateY(calc(50rem/16)) !important;
    }
}
