.hero-blog__author-infos {
    color:#fff;
    font-family: var(--font-default-bold);
    font-size: calc(26rem/16);
    text-shadow: 0 calc(2rem/16) calc(15rem/16) rgba(0,0,0,0.5);
    margin-bottom: calc(15rem/16);
}
.hero-blog__author-media  {
    border-radius: 50%;
    width: calc(38rem/16);
    height: calc(38rem/16);
    border: calc(3rem/16) solid #fff;
    box-shadow: 0 calc(13rem/16) calc(18rem/16) 0 rgba(51,51,51,0.2);
}
.hero-blog__author-infos {
    display:flex;
    justify-content: center;
    align-items:center;
    @media screen and (max-width: 767px) {
        display:block;
        font-size: calc(20rem/16);
        line-height:1.2;
        text-align:center;
    }
}
.hero-blog__author-info {
    padding-left: calc(8rem/16);
    padding-right: calc(8rem/16);
    @media screen and (max-width: 767px) {
        margin: 0 auto;
    }
}
.hero-blog__author-date {
    padding:0;
}
.hero-blog__author-date:after {
    content: "·";
    padding-left: calc(3rem/16);
}
.hero-blog__title {
    font-size: calc(55rem/16);
    text-align:center;
    margin-bottom: calc(80rem/16);
    @media screen and (max-width: 1400px) and (min-width: 768px) {
        margin-bottom: calc(40rem/16);
    }
    @media screen and (max-width: 767px) {
        margin-bottom: calc(20rem/16);
        font-size: calc(35rem/16);
        line-height: 1.2;
    }
}
.hero-blog__content.hero__content {
    bottom:0;
    margin-left:0;
    padding-left:0;
}
.hero-blog__text-wrapper {
    background-color:#fff;
    padding: calc(90rem/16) calc(10rem/16) 0;
    color: var(--color-text-default);
    line-height: calc(36/18);
    @media screen and (max-width: 1400px) {
        padding: calc(20rem/16) calc(20rem/16) 0;
    }
    @media screen and (max-width: 767px) {
        line-height: 1.6;
    }
}
.hero-blog__text::first-letter {
    font-size: calc(108rem/16);
    float: left;
    line-height: 1;
    padding-right: calc(10rem/16);
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size: calc(50rem/16);
    }
}
.hero-blog__text {
    @media screen and (min-width: 768px) {
        max-width: calc(770rem/16);
        padding: calc(10rem/16);
        margin: 0 auto;
    }
}