.simple-img-teaser__embed:before {
    padding-top: calc( 370 / 442 * 100%);
}
.simple-img-teaser {
    position: relative;
    cursor:pointer;
}
.simple-img-teaser:before {
    background: linear-gradient(180deg, rgba(47,66,80,0) 0%, rgba(47, 66, 80, 0.94) 100%);
    content:'';
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 1;
    transition: opacity 0.7s ease;
}
.simple-img-teaser__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color:#fff;
    padding: calc(20rem/16) calc(23rem/16);
    text-align:center;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16) calc(10rem/16);
    }
}
.simple-img-teaser__title {
    font-size: calc(34rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(41/34);
    text-shadow: 0 calc(2rem/16) calc(30rem/16) rgba(0,0,0,0.3);
    margin-bottom:0;
    text-transform:uppercase;
    position: relative;
    transition: transform 0.4s ease-out;

    @media screen and (max-width: 1199px) {
        font-size: calc(30rem/16);
    }
}
.simple-img-teaser__discount-badge {
    transition: transform 0.2s ease-out;
    transform-origin:right top;
    position:absolute;
    right:0;
    top: calc(5rem/16);

}
.simple-img-teaser__logo {
    margin: calc(10rem/16) auto;
}
/* hover states */
@media screen and (min-width: 1200px) {
    .simple-img-teaser__media {
        transition: transform 0.5s ease-in-out;
    }
    .simple-img-teaser:hover .simple-img-teaser__media {
        transform: scale(1.05);
    }
    .simple-img-teaser:hover .simple-img-teaser__title {
        transform: translateY(calc(-30rem/16));
    }
    .simple-img-teaser:hover .simple-img-teaser__discount-badge {
        transform: translateX(calc(-5rem/16));
    }
    .simple-img-teaser:hover:before {
        opacity:0.6;
    }
    .simple-img-teaser__title:after {
        content: "";
        height: .25rem;
        background-color: var(--color-primary);
        display: block;
        bottom: 0;
        margin: 0 auto;
        left: 0;
        width: 2.5rem;
        right: 0;
        transform: scaleX(0);
        opacity: 0;
        transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    }
    .simple-img-teaser:hover .simple-img-teaser__title:after {
        opacity:1;
        transform: scaleX(1) translateY(calc(8rem/16));
    }
}