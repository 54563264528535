:root {
    --hero-quickfinder-height: calc(170rem/16);
    --hero-space-bottom: calc(80rem/16);
    --hero-space-bottom-xs: calc(20rem/16);
}

.hero {
    position: relative;
    padding-bottom: calc(28rem/16);
    @media screen and (max-width: 767px) {
        padding-bottom: calc(10rem/16);
    }
}
.hero--more-spacing {
    margin-bottom: calc(107rem/16);
    padding-bottom: var(--hero-space-bottom);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(20rem/16);
        padding-bottom: var(--hero-space-bottom-xs);
    }
}

.hero__embed-small:before {
    padding-top:calc(100%);
    @media screen and (min-width: 1200px) {
        padding-top: calc( 13 / 32 * 100%);
    }
    @media screen and (min-width: 767px) and (max-width: 1199px){
        padding-top: 80%;
    }
}
.hero__embed-small {
    @media screen and (min-width: 768px) {
        transition: transform 8s ease-in-out;
        transform: scale(1);
    }
}
.is-in-viewport.hero--zoom .hero__embed-small {
    @media screen and (min-width: 768px) {
        transform: scale(1.06);
    }
}

.hero__embed {
    @media screen and (min-width: 768px) {
        transition: transform 8s ease-in-out;
        transform: scale(1);
        transform-origin: bottom;
        min-height: calc(700rem/16);
    }
}
.is-in-viewport.hero--zoom .hero__embed {
    @media screen and (min-width: 768px) {
        transform: scale(1.06);
        transform-origin: bottom;
    }
}
.hero__embed:before {
    padding-top:  calc(100vh - var(--hero-space-bottom));
    pointer-events: none;

    @media screen and (max-width: 767px) and (orientation: landscape) {
        padding-top: 150vh;
    }
    @media screen and (max-width: 767px) and (orientation: portrait) {
        padding-top: 125%;
    }
}
.hero__embed-small:before {
    @media screen and (max-width: 767px) and (orientation: landscape) {
        padding-top: 150vh;
    }
@media screen and (max-width: 767px) and (orientation: portrait) {
        padding-top: 125%;
    }
}
.hero__image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    pointer-events: none;
    @media screen and (max-width: 767px) {
        background-image: none !important; /* needed for usage without devicehelper*/
    }
}

/* hero quickfinder */
.hero__quick-finder {
    position: absolute;
    bottom:0;
    width: 50%;
    background-color:#fff;
    height: var(--hero-quickfinder-height);
    font-size: calc(20rem/16);
    text-transform:uppercase;
    display: flex;
    align-items: center;
    z-index: 2;
    box-shadow: 0 calc(28rem/16) calc(40rem/16) 0 rgba(5,30,45,0.1);
}
.hero__quick-finder__content {
    padding-left: calc((100vw - calc(1460rem/16) + calc(15rem/16)) / 2);
    height: 100%;
    width: 100%;
    @media screen and (max-width: 1500px) {
        padding-left: calc(15rem/16);
    }
}
.hero__quick-finder__submit {
    background-color: var(--color-booking);
    color:#fff;
    height:100%;
    width: 100%;
    padding: calc(20rem/16);
    font-size: calc(19rem/16);
    line-height: calc(24/19);
    text-align:center;
    display:flex;
    justify-content:center;
    align-items:center;
    font-family: var(--font-default-bold);
    flex-direction: column;
    transition: background-color 0.3s ease;
}
.hero__quick-finder__submit:hover {
    background-color: var(--color-booking-dark);
}
.hero__quick-finder__submit-icon {
    font-size: calc(40rem/16);
    display:block;
    margin-bottom: calc(11rem/16);
}

/* hero media */
.hero__bg-video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow:hidden;
    position:absolute;
}
.hero__bg-video__video, .hero__bg-video video {
    min-width: 100%;
    min-height: 100%;
    width: auto !important;
    height: auto !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%,-50%);
}
/* hero content */
.hero__content {
    position: absolute;
    z-index: 2;
    width:100%;
    bottom: calc(90rem/16);
    color:#fff;
    @media screen and (max-width: 1700px) and (min-width: 768px){
        padding-left: calc(200rem/16);
    }
    @media screen and (max-width: 767px) {
        bottom: calc(30rem/16);
    }
}
.hero--more-spacing .hero__content {
    bottom: calc(170rem/16);
    @media screen and (max-width: 767px) {
        bottom: calc(40rem/16);
    }
}
.hero__main {
    position:relative;
    @media screen and (min-width: 768px) {
        overflow:hidden;
    }
}
.hero__main:after {
    background: linear-gradient(0deg, rgba(47,66,80,0) 0%, #2F4250 100%);
    content:'';
    opacity:0.7;
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:50%;
    pointer-events: none;
}
.is-in-viewport .hero__main:before {
    opacity:0;
    pointer-events: none;
}
.hero__subtitle {
    font-size: calc(26rem/16);
    line-height: calc(30/26);
    text-shadow: 0 2px 30px rgba(0,0,0,0.9);
    text-transform:uppercase;
    @media screen and (max-width: 1399px) and (min-width: 768px) {
        font-size: calc(18rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: 1rem;
        margin-bottom: calc(5rem/16);
    }
}
.hero__title {
    font-size: calc(80rem/16);
    line-height: 1;
    text-shadow: 0 2px 30px rgba(0,0,0,0.9);
    font-family: var(--font-default-bold);
    margin-bottom: calc(35rem/16);
    @media screen and (max-width: 1399px) and (min-width: 768px) {
        font-size: calc(60rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(40rem/16);
        line-height: calc(45/40);
        margin-bottom:calc(20rem/16);
    }
}
@media screen and (min-width: 768px) {
    .hero__title, .hero__subtitle, .hero__btn {
        opacity:0;
        transform: translateY(20px);
    }
    .is-in-viewport .hero__title, .is-in-viewport .hero__subtitle, .is-in-viewport .hero__btn {
        opacity:1;
        transform: translateY(0px);
    }
    .hero__title {
        transition: opacity 0.9s ease-out 0.3s, transform 0.9s cubic-bezier(0.4, 0, 0.3, 1) 0.3s;
    }
    .hero__subtitle{
        transition: opacity 0.9s ease-out 0.3s, transform 0.9s cubic-bezier(0.4, 0, 0.3, 1) 0.3s;
    }
    .hero__btn{
        transition: opacity 0.9s ease-out 0.3s, transform 0.9s cubic-bezier(0.4, 0, 0.3, 1) 0.3s;
    }
}

.hero--microsite .hero__title {
    font-size: calc(110rem/16);
    font-family: var(--font-decorative);
    @media screen and (max-width: 767px) {
        font-size: calc(50rem/16);
    }
}
.hero--microsite .hero__content {
    top: calc(100rem/16);
    bottom: calc(100rem/16);
    display: flex;
    justify-content:center;
    align-items:center;
}
.hero-slider__arrow-wrapper {
    position:absolute;
    bottom:0;
    right:0;
    z-index: 3;
}
.hero__badge {
    width: calc(124rem/16);
    height: calc(124rem/16);
    position: absolute;
    bottom:0;
    right:0;
    background-color: var(--color-primary);
    color:#fff;
    font-size: calc(80rem/16);
    border-radius: 50%;
    display:flex;
    justify-content: center;
    align-items:center;
    margin-bottom: calc(60rem/16);
    transition: background-color 0.3s ease;
    box-shadow:0 0.8125rem 1.125rem 0 rgba(51,51,51,.2);
    @media screen and (max-width: 767px) {
        width: calc(50rem/16);
        height: calc(50rem/16);
        font-size: calc(30rem/16);
        margin-bottom: calc(20rem/16);
        margin-right: calc(15rem/16);
    }
}
.hero__container--with-badge.container {
    @media screen and (max-width: 767px) {
        padding-right: calc(70rem/16);
    }
}
.hero__badge.badge-animation {
    animation: badgeScale 0.3s 2 forwards;
}
.hero__badge:hover {
    background-color: var(--color-primary-dark);
    transform: scale(1.1);
}
@keyframes badgeScale {
    0% {
        transform: scale(1);
    }
    60% {
        transform: scale(1.08);
    }
    100% {
        transform: scale(1);
    }
}