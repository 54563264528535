.package-teaser__embed:before {
    padding-top: 59%;
}
.package-teaser {
    display:flex;
    flex-direction: column;
    box-shadow: 0 calc(20rem/16) calc(40rem/16) 0 rgba(35,59,72,0.1);
}
.package-teaser__title {
    font-family: var(--font-default-bold);
    margin-top: calc(5rem/16);
    margin-bottom: calc(0rem/16);
    font-size: calc(26rem/16);
}
.package-teaser__subtitle {
    font-size: calc(16rem/16);
    margin-bottom: calc(10rem/16);
}
.package-teaser__date {
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    color: var(--color-primary);
}
.package-teaser__body {
    padding: calc(30rem/16) calc(30rem/16) calc(30rem/16);
    display:flex;
    flex-direction: column;
    flex:1;
}
.package-teaser__img {
    transition: transform 0.3s ease;
}
.package-teaser:hover .package-teaser__img{
    transform: scale(1.1);
}
.package-teaser__content {
    font-size: calc(16rem/16);
    line-height: calc(19rem/16);
    margin-top: calc(20rem/16);
    margin-bottom: calc(20rem/16);
}
.package-teaser__price {
    font-family: var(--font-default-bold);
    font-size: calc(22rem/16);
}
