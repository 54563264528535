.pagination {
    position: relative;
    @media screen and (min-width: 768px) {
        margin-bottom: calc(40rem/16);
    }
}
.pagination .page-link {
    position: relative;
    padding: calc(5rem/16);
    color: var(--color-grey);
    opacity: 0.4;
    font-size: calc(20rem/16);
    font-family: var(--font-default-bold);
    background-color: transparent;
    border: none;
}

.pagination .page-item.active .page-link {
    color: var(--color-grey);
    opacity: 1;
    background-color: transparent;
}

.pagination .page-item.disabled {
    opacity: 0.4;
    pointer-events: none;
}
.pagination .page-item.disabled .page-link {
    background-color: transparent;
}
.pagination .page-item.page-arrow {
    position: absolute;
    top: 0;
    transform: translateY(-25%);
    padding: calc(40rem/16);
    width: calc(100rem/16);
    height: calc(100rem/16);
    background-color: var(--color-primary);

    @media screen and (max-width: 767px) {
        transform: none;
        padding: calc(13rem/16);
        width: calc(40rem/16);
        height: calc(40rem/16);
    }
}
.pagination .page-item.page-arrow:not(.disabled) {
    cursor: pointer;
}
.pagination .page-item.page-arrow .page-link {
    color: #ffffff;
    opacity: 1;
    padding: 0;
    font-size: calc(16rem/16);

    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}
.pagination .page-item.page-arrow.page-arrow-prev {
    right: calc(105rem/16);

    @media screen and (max-width: 767px) {
        right: unset;
        left: 0;
    }
}
.pagination .page-item.page-arrow.page-arrow-next {
    right: 0;
}
.pagination .li-has-arrow.page-item .page-link {
    background-color: var(--color-primary);
    color:#fff;
    border-radius:0;
    opacity:1;
    width: calc(30rem/16);
    height: calc(35rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 calc(10rem/16);
    font-size: calc(14rem/16);
}