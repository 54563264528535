@font-face {
    font-family: 'Tondo-Bold';
    src: url('/static/fonts/tondo-bold.woff2') format('woff2'),
    url('/static/fonts/tondo-bold.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open-Sans-Reg';
    src: url('/static/fonts/OpenSans-Regular.woff2') format('woff2'),
    url('/static/fonts/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

