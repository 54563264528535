.webcam-teaser-detail__embed:before {
    padding-top: calc( 335 / 700 * 100%);
}

.webcam-teaser-detail {
    position: relative;
    cursor :pointer;
}
.webcam-teaser-detail:before {
    background: linear-gradient(180deg, rgba(47,66,80,0) 0%, rgba(47,66,80,0.9) 100%);
    content:'';
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 1;
    transition: opacity 0.7s ease;
}
.webcam-teaser-detail__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    color:#fff;
    padding: calc(20rem/16);
}
.webcam-teaser-detail__title {
    font-size: calc(20rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    text-shadow: 0 2px 30px 0 rgba(0,0,0,0.3);
}
.webcam-teaser-detail__logo-img {
    position: absolute;
    right: calc(10rem/16);
    top: calc(10rem/16);
    max-width: calc(70rem/16);
    z-index: 1;
    @media screen and (min-width: 768px) {
        max-width: calc(100rem/16);
    }
}

@media screen and (min-width: 768px) {
    .webcam-teaser-detail__media {
        transition: transform 0.5s ease-in-out;
    }
    .webcam-teaser-detail:hover .webcam-teaser-detail__media {
        transform: scale(1.05);
    }
    .webcam-teaser-detail__title {
        transition: transform 0.25s ease-in-out;
    }
    .webcam-teaser-detail:hover .webcam-teaser-detail__title {
        transform: translateY(calc(-20rem/16));
    }
    .webcam-teaser-detail:hover:before {
        opacity:0.6;
    }
    .webcam-teaser-detail__body {
        transition: transform 0.25s ease-in-out;
    }
    .webcam-teaser-detail:hover .webcam-teaser-detail__body {
        transform: translateY(calc(-10rem/16));
    }
    .webcam-teaser-detail__title:after {
        content: "";
        height: .25rem;
        background-color: var(--color-primary);
        display: block;
        bottom: 0;
        left: 0;
        width: 2.5rem;
        right: 0;
        transform: scaleX(0);
        opacity: 0;
        transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    }

    .webcam-teaser-detail:hover .webcam-teaser-detail__title:after {
        opacity:1;
        transform: scaleX(1) translateY(calc(8rem/16));
    }
}

/* grid */
.webcam-teaser-detail-grid__logo {
    max-width: calc(130rem/16);
}