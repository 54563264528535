.blogger-teaser {
    padding: calc(10rem/16);
    text-align:center;
}
.blogger-teaser__author-wrapper {
    border: calc(8rem/16) solid #FFFFFF;
    box-shadow: 0 calc(13rem/16) calc(18rem/16) 0 rgba(51,51,51,0.2);
    border-radius: 50%;
}
.blogger-teaser__body {
    padding-top: calc(33rem/16);
}
.blogger-teaser__author {
    margin:0;
    font-size: calc(18rem/16);
    font-family: var(--font-default-bold);
}
.blogger-teaser__article-count {
    font-size: 1rem;
    margin-bottom: calc(22rem/16);
}