.webcam-teaser-wide__embed:before {
    padding-top: calc( 335 / 701 * 100%);
}
.webcam-teaser__embed:before {
    padding-top: calc( 14 / 19 * 100%);
    @media screen and (max-width: 767px) {
        padding-top: 76.84%;
    }
}

.webcam-teaser {
    position: relative;
    cursor:pointer;
}
.webcam-teaser__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    color:#fff;
    padding: calc(30rem/16) calc(23rem/16);
    text-align:center;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16) calc(10rem/16);
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        padding: calc(15rem/16);
    }
}
.webcam-teaser__title {
    font-size: calc(30rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(50rem/30);
    text-shadow: 0 2px 10px 0 rgba(0,0,0,0.5);
    @media screen and (max-width: 1199px) {
        font-size: calc(20rem/16);
        line-height: calc(22rem/20);
    }
}
.webcam-overlay .webcam-teaser__title {
    @media screen and (max-width: 1499px) {
        font-size: calc(20rem/16);
    }
}
.webcam-teaser__button {
    margin-top: calc(20rem/16);
    @media screen and (min-width: 1200px) {
        margin-top: calc(70rem/16);
    }
}
.webcam-overlay .webcam-teaser__button {
    @media screen and (max-width: 1499px) {
        margin-top: calc(20rem/16);
    }
}

/* badge */
.webcam-teaser__badge {
    position: absolute;
    display: flex;
    right: 0;
    top: 0;
    z-index: 1;
    font-size: calc(20rem/16);

    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
    }
}
.webcam-teaser__badge-item {
    padding: calc(10rem/16);
    display: flex;

    @media screen and (max-width: 767px) {
        padding: calc(8rem/16);
    }
}
.webcam-teaser__badge-item .icon {
    font-size: calc(30rem/16);

    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
    }
}
.webcam-teaser__badge-text {
    position: relative;
    padding-left: calc(45rem/16);
    padding-right: calc(25rem/16);

    @media screen and (max-width: 767px) {
        padding-left: calc(35rem/16);
        padding-right: calc(5rem/16);
    }
}
.webcam-teaser__badge-text:before {
    content: '';
    position: absolute;
    height: calc(32rem/16);
    width: calc(32rem/16);
    left: calc(5rem/16);
    top: 0;
    background-color: var(--color-secondary);
    border-radius: 50%;
    opacity: 0.3;
    animation: blink 2s ease infinite;

    @media screen and (max-width: 767px) {
        height: calc(26rem/16);
        width: calc(26rem/16);
        left: calc(2rem/16);
    }
}
.webcam-teaser__badge-text:after {
    content: '';
    position: absolute;
    height: calc(12rem/16);
    width: calc(12rem/16);
    left: calc(15rem/16);
    top: calc(10rem/16);
    background-color: var(--color-secondary);
    border-radius: 50%;
    opacity: 1;

    @media screen and (max-width: 767px) {
        height: calc(10rem/16);
        width: calc(10rem/16);
        left: calc(10rem/16);
        top: calc(8rem/16);
    }
}

@keyframes blink {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    100% {
        transform: scale(1.2);
        opacity: 0.1;
    }
}

@media screen and (min-width: 1200px) {
    .webcam-teaser:before {
        background: linear-gradient(180deg, rgba(47,66,80,0) 0%, rgba(47,66,80,0.9) 100%);
        content:'';
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index: 1;
        opacity: 0;
        transition: opacity 0.7s ease;
    }
    .webcam-teaser:hover:before {
        opacity:0.6;
    }
    .webcam-teaser__title {
        transition: transform 0.25s ease-in-out;
    }
    .webcam-teaser:hover .webcam-teaser__title {
        transform: translateY(calc(-20rem/16));
    }
    .webcam-teaser__title:after {
        content: "";
        height: .25rem;
        background-color: var(--color-primary);
        display: block;
        bottom: 0;
        left: 0;
        margin: 0 auto;
        width: 2.5rem;
        right: 0;
        transform: scaleX(0);
        opacity: 0;
        transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    }
    .webcam-teaser:hover .webcam-teaser__title:after {
        opacity:1;
        transform: scaleX(1) translateY(calc(8rem/16));
    }
}

/* grid */
.webcam-teaser-grid__logo {
    max-width: calc(130rem/16);
}