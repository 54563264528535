.accommodation-item {
    display: flex;
    margin-bottom: calc(10rem/16);
}
.accommodation-item__title {
    background-color:#fff;
    color: var(--color-primary);
    padding: calc(5rem/16) calc(15rem/16);
    flex-grow: 1;
    display:flex;
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}
.accommodation-item__remove {
    background-color: var(--color-primary-light);
    color: var(--color-primary);
    width: calc(40rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
}