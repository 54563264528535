.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 2em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
    text-decoration: underline;
}

.list-default, .wysiwyg ul {
    text-indent: calc(-18rem/16);
    padding-left: calc(18rem/16);
    list-style-type: none;
}
.list-default>li:before, .wysiwyg ul>li:before {
    content: "";
    display: inline-block;
    width: .45rem;
    height: .45rem;
    background: var(--color-text-default);
    border-radius: 50%;
    padding-left: 0;
    margin-bottom: calc(4rem/16);
    margin-right: calc(11rem/16);
    list-style-type: none;
    vertical-align: middle;
}