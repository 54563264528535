.social-wall-cta__media:after {
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
    content:'';
    position: absolute;
    width:100%;
    height:50%;
    bottom:0;
    left:0;
}
.social-wall-cta__media {
    position: relative;
}
.social-wall-cta__btn-wrapper {
    z-index: 3;
    position:relative;
    margin-top: calc(20rem/16);
    @media screen and (min-width: 768px) {
        margin-top: calc(-50rem/16);
    }
}
.social-wall-cta {
    margin-bottom: calc(30rem/16);
    @media screen and (min-width: 768px) {
        margin-bottom: calc(45rem/16);
    }
}