.form-group-datepicker__label {
    font-size: 1rem;
    line-height: calc(19/16);
    font-family: var(--font-default-bold);
}
.form-group-datepicker__input, .form-group-datepicker__input[readonly] {
    border-radius:0;
    border: 0;
    font-size: 1rem;
    line-height: 1.5;
    border-bottom: calc(2rem/16) solid var(--color-primary-light);
    background-color: transparent;
    font-family: var(--font-default);
    color: var(--color-text-default);
    padding-left:0;
    height: calc(40rem/16);
}
.form-group-datepicker:before {
    position:absolute;
    pointer-events:none;
    content: var(--icon-cal);
    display:block;
    font-family: 'iconfont';
    speak: none;
    font-style: normal;
    font-weight: 400;
    color: var(--color-primary-light);
    right: 0;
    bottom:calc(5rem/16);
}
.form-group-datepicker {
    position: relative;
}
.input--no-controls[type=number]::-webkit-inner-spin-button,
.input--no-controls[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
.input--no-controls[type=number] {
    -moz-appearance: textfield;
}