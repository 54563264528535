.img-preview__upload-wrapper {
    display: block;
    background-color: rgba(246, 246, 246, 0.63);
    border: 1px solid var(--color-primary);
}
.img-preview__upload-text {
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}
.img-preview__upload-icon {
    font-size: calc(130rem/16);
    color: var(--color-primary);
    opacity: .1;
    position: absolute;
    margin: 0 auto;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
        font-size: calc(90rem/16);
    }
}
.img-preview__result {
    max-width: calc(210rem/16);
    max-height: calc(210rem/16);
    @media screen and (max-width: 767px) {
        max-width: 100%;
    }
}