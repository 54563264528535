.blog-item__embed:before {
    padding-top: calc( 420 / 335 * 100%);
    @media screen and (max-width: 767px) {
        padding-top: 60%;
    }
}

.blog-item--pic-left .blog-item__body {
    @media screen and (min-width: 768px) {
        padding-left: calc(100rem/16);
    }
}
.blog-item--pic-right .blog-item__body {
    @media screen and (min-width: 768px) {
        padding-right: calc(100rem/16);
    }
}
.blog-item--pic-left .blog-item__body--small.blog-item__body {
    @media screen and (min-width: 768px) {
        padding-left: calc(40rem/16);
    }
}
.blog-item--pic-right .blog-item__body--small.blog-item__body {
    @media screen and (min-width: 768px) {
        padding-right: calc(40rem/16);
    }
}
.blog-item__body {
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16) calc(0rem/16);
    }
}
.blog-item__media {
    box-shadow: 0 calc(20rem/16) calc(40rem/16) 0 rgba(35,59,72,0.3);
}
.blog-item__title {
    font-size: calc(30rem/16);
    font-family: var(--font-default-bold);
    line-height:1;
    margin-bottom: calc(20rem/16);
    margin-top: calc(16rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
    }
}
.blog-item__content {
    margin-bottom: calc(40rem/16);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    @media screen and (max-width: 767px) {
        font-size:1rem;
        margin-bottom: calc(10rem/16);
    }
}
.blog-item__category {
    font-family: var(--font-default-bold);
    display:inline-block;
}
.blog-item__date {
    display:inline-block;
    @media screen and (max-width: 767px) {
        display:block;
    }
}
.blog-item__date:before {
    @media screen and (min-width: 768px) {
        content: "·";
        color: var(--color-light-grey);
        font-size: calc(25rem/16);
        padding-left: calc(4rem/16);
        padding-right: calc(4rem/16);
        font-family: var(--font-default-bold);
    }
}
.blog-link:hover {
    color: var(--color-primary);
}