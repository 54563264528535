.nav-tabs--primary-center {
    width: auto;
    height:2.5rem;
    display: inline-flex;
    border:2px solid var(--color-primary);
    margin:0;
    background:var(--color-primary);
    border-radius:1.25rem;
}
.nav-tabs.nav-tabs--primary-center .nav-link {
    height:2.5rem;
    line-height:2.5rem;
    color:#fff;
    border:0;
    padding:0 3rem;
    margin:0;
    border-radius:1.25rem;
    text-transform: uppercase;
    font-family: var(--font-default-bold);
}
.nav-tabs.nav-tabs--primary-center .nav-link:hover,
.nav-tabs.nav-tabs--primary-center .nav-link:focus,
.nav-tabs.nav-tabs--primary-center .nav-item > .nav-link.active {
    color: var(--color-primary);
    background:#fff;
}


@media screen and (min-width: 768px) {
    .nav-tabs--primary-center {
        height:3.25rem;
        border-radius:10rem;
    }
    .nav-tabs.nav-tabs--primary-center .nav-link {
        height:3rem;
        line-height:3rem;
        border-radius:1.5rem;
    }
}




@media screen and (max-width: 767px) {
    .nav-tabs--primary-center {
        height:auto;
        display: block;
    }
    .nav-tabs.nav-tabs--primary-center .nav-item {
        display: block;
        width: 100%;
    }
    .nav-tabs.nav-tabs--primary-center .nav-link {
        display: block;
        width: 100%;
    }
}