.info-box__title {
    font-family: var(--font-default-bold);
    color: var(--color-primary);
    margin-bottom: calc(5rem/16);
}
.info-box__content {
    margin-bottom: calc(30rem/16);
    font-size: calc(16rem/16);
}
.info-box {
    padding: calc(50rem/16);
    border: 1px solid var(--color-primary);
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
.info-box__icon {
    color: var(--color-primary);
    font-size: calc(20rem/16);
}
.info-box__row + .info-box__row {
    margin-top: calc(10rem/16);
}
.info-box__link {
    color: var(--color-primary);
}