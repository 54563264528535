.text-teaser__title {
    font-family: var(--font-default-bold);
    text-transform:uppercase;
    margin-bottom:0;
}
.text-teaser {
    box-shadow: 0 1.25rem 2.5rem 0 rgba(35,59,72,.1);
    padding: calc(25rem/16);
    background-color:#fff;
    position: relative;
    cursor:pointer;
}
.text-teaser__content {
    margin-top: calc(10rem/16);
    margin-bottom: calc(10rem/16);
    font-size: calc(16rem/16);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.text-teaser__link {
    margin-top: calc(20rem/16);
}
.text-teaser__content:before {
    content: "";
    height: .25rem;
    background-color: var(--color-secondary);
    display: block;
    bottom: 0;
    margin: 1.25rem 0;
    left: 0;
    width: 2.5rem;
    right: 0;
}
.text-teaser:before {
    content:'';
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity:0;
    pointer-events: none;
    transition: opacity 0.3s ease;
    box-shadow: 0 1.25rem 2.5rem 0 rgba(35,59,72,.3);
}
.text-teaser:hover:before{
    opacity:1;
}