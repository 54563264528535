.main-nav {
    position:absolute;
    z-index: 199;
    width:100%;
    color:#fff;
    background-color:transparent;
    transition: background-color 0.3s ease, color 0.3s ease;
    overflow:visible;
}
.main-nav__brand-img-wrapper {
    padding-right: calc(10rem/16);
}
.main-nav__brand-img-wrapper:before {
    content:'';
    position:absolute;
    width: 8.5rem;
    height: 9rem;
    background-color:var(--color-secondary);
    transform: skew(-40deg) translateX(-18%);
    z-index: -1;
    top:0;
    pointer-events:none;
    opacity:1;
    transition: opacity 0.3s ease;
    @media screen and (max-width: 1400px) and (min-width: 992px) {
        width: 6.5rem;
        height: 7rem;
    }
    @media screen and (max-width: 991px) {
        width: 6.5rem;
        height: 7rem;
    }
    @media screen and (max-width: 350px) {
        width: 5.5rem;
        height: 5rem;
    }
}
.main-nav__brand-img {
    @media screen and (max-width: 1600px) and (min-width: 992px) {
        max-width: calc(140rem/16);
    }
    @media screen and (max-width: 1400px) and (min-width: 351px){
        max-width: calc(130rem/16);
    }
    @media screen and (max-width: 350px) {
        max-width: calc(110rem/16);
    }
}
.main-nav__dropdown .main-nav__dropdown-toggle:after {
    display:none;
}
.main-nav__dropdown-icon {
    font-size: calc(8rem/16);
    margin-left: calc(5rem/16);
}
.main-nav__nav-wrapper {
    max-width: 20%;
    width:100%;
    display:flex;
    align-items:center;
    @media screen and (max-width: 1600px) {
        max-width: initial;
        display: inline-flex;
        width: auto;
    }
}
.main-nav__nav {
    height:100%;
    @media screen and (max-width: 767px) {
        height: auto;
    }
}
.main-nav__nav-wrapper--center {
    display:flex;
    justify-content:center;
    align-items:center;
}
.main-nav__nav-link  {
    font-size: 1rem;
    line-height: calc(19/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    @media screen and (min-width: 768px) {
        color:#fff;
    }
    @media screen and (max-width: 1650px) and (min-width: 1310px) {
        font-size: calc(14rem/16);
    }
    @media screen and (max-width: 1309px) and (min-width: 992px) {
        font-size: calc(12rem/16);
    }
}
.main-nav__nav-link-icon {
    display: none;
    @media screen and (max-width: 991px) {
        display: inline-block;
        font-size: calc(14rem/16);
        color: var(--color-primary);
        padding-left: calc(5rem/16);
    }
}
.main-nav__dropdown-language-switch .main-nav__dropdown-toggle{
    text-transform:uppercase;
    font-family:var(--font-default-bold);
    font-size: 1rem;
    @media screen and (max-width: 1400px) and (min-width: 992px) {
        font-size: calc(14rem/16);
    }
}
.main-nav__dropdown-region-switch .main-nav__dropdown-toggle {
    font-size: calc(18rem/16);
    @media screen and (max-width: 1400px) and (min-width: 992px) {
        font-size: calc(14rem/16);
    }
}
.main-nav__dropdown-toggle {
    display:flex;
    justify-content:center;
    align-items:center;
}
.main-nav__dropdown-region-switch .main-nav__dropdown-icon {
    opacity:0.7;
}
.dropdown-menu.dropdown-menu--mobile {
    @media screen and (max-width: 767px) {
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateY(-100%);
    }
}
.dropdown-menu.dropdown-menu--mobile.show {
    position: absolute;
    display: flex;
    flex-direction: column;
}
.main-nav__nav-item--only-icon .main-nav__nav-link {
    font-size: calc(25rem/16);
    @media screen and (max-width: 1400px) and (min-width: 992px) {
        font-size: calc(18rem/16);
    }
}
.main-nav__nav-item--booking {
    display:none;
    background-color: var(--color-booking);
    color:#fff;
}
.main-nav__nav-item {
    @media screen and (min-width: 992px) {
        position:relative;
    }
}
.main-nav__dropdown-language-switch {
    margin-right: calc(10rem/16);
}
.main-nav__nav-item:before {
    content:'';
    position:absolute;
    bottom:0;
    margin:0 auto;
    width: calc(30rem/16);
    height: calc(2rem/16);
    left:0;
    right:0;
}
.main-nav.is-nav-white .main-nav__nav-item:before {
    @media screen and (min-width: 768px) {
        background-color: transparent;
        width: 0;
        height: 0;
    }
}
.main-nav__nav-item:hover, .main-nave__nav-item:active {
    color: var(--color-primary-dark);
}

.main-nav__nav-item:hover .main-nav__nav-link {
    @media screen and (min-width: 768px) {
        color: var(--color-primary-dark);
    }
}

.main-nav__nav-wrapper--right .main-nav__nav-item:hover {
    color: initial;
}
.main-nav__nav-item-text {
    margin-left: calc(27rem/16);
}
.main-nav.is-nav-white .main-nav__nav-item-text {
    @media screen and (max-width: 1400px) and (min-width: 992px) {
        margin-left:0;
        margin-top: calc(10rem/16);
    }
}

.main-nav.is-nav-white .main-nav__nav, .is-nav-white .main-nav__nav-item {
    color: var(--color-primary-dark);
}
.main-nav.is-nav-white .main-nav__nav-link {
    @media screen and (min-width: 768px) {
        color: var(--color-primary-dark);
        padding-top: calc(42rem/16);
        padding-bottom: calc(36rem/16);
    }
}

.main-nav--initial-relative {
    position:relative;
}

    /* affix */
.main-nav.is-affix {
    position:fixed;
    width: 100%;
    left:0;
    right:0;
    top:0;
}
.main-nav.is-nav-white, .main-nav--initial-white, .main-nav--lp  {
    padding:0;
    @media screen and (min-width: 992px) {
        height: var(--main-nav-height);
    }
}
.main-nav.is-nav-white .main-nav__brand-img-wrapper:before, .main-nav--initial-white .main-nav__brand-img-wrapper:before {
   opacity:0;
}
.main-nav.is-nav-white, .main-nav--initial-white {
    background-color:#fff;
    color:var(--color-text-default);
}
.main-nav.is-nav-white .main-nav__nav-link, .main-nav--initial-white .main-nav__nav-link {
    color:var(--color-text-default);
    @media screen and (min-width: 768px) {
        color: var(--color-text-default);
    }
}
.main-nav.is-nav-white .main-nav__nav-item:hover .main-nav__nav-link, .main-nav--initial-white .main-nav__nav-item:hover .main-nav__nav-link {
    color: var(--color-primary-dark);
}

.main-nav.is-nav-white .main-nav__dropdown-language-switch  {
    opacity:0;
    display:none;
}
.main-nav.is-nav-white .main-nav__nav-wrapper, .main-nav.is-nav-white .main-nav__nav-wrapper {
    max-width: initial;
    width: auto;
}
.main-nav.is-nav-white .main-nav__nav-wrapper--right {
    height:100%;
}
.main-nav.is-nav-white .main-nav__nav-item--booking {
    display:block;
    height:100%;
    max-width: calc(140rem/16);
    @media screen and (max-width: 1199px) and (min-width: 992px) {
        max-width: initial;
    }

}

.main-nav.is-nav-white .main-nav__dropdown-region-switch .main-nav__dropdown-icon {
    color: var(--color-primary-light);
    opacity:1;
}
.main-nav__nav-item--booking .main-nav__nav-item-btn {
    height:100%;
    display: flex;
    justify-content:center;
    align-items:center;
    border-radius:0;
    color:#fff;
    flex-direction: column;
    @media screen and (max-width: 1199px) and (min-width: 992px) {
        padding: calc(10rem/16) calc(15rem/16);
        font-size: calc(18rem/16);
    }
    @media screen and (max-width: 991px) {
        height: calc(50rem/16);
        width: calc(50rem/16);
        border-radius:0;
        padding: calc(5rem/16);
    }
}
.main-nav__nav-item--booking .main-nav__nav-item-btn .icon {
    font-size: calc(30rem/16);
    @media screen and (max-width: 1400px) {
        font-size: calc(20rem/16);
    }
}
.main-nav.is-nav-white .main-nav__nav-item--tickets, .main-nav--lp .main-nav__nav-item--tickets {
    text-transform:uppercase;
    height:100%;
    @media screen and (min-width: 992px) {
        margin-left: calc(20rem/16);
    }
}
.main-nav__nav-item--tickets .main-nav__nav-item-btn {
    height:100%;
    display: flex;
    justify-content:center;
    align-items:center;
    @media screen and (max-width: 1400px) and (min-width: 1200px) {
        padding-left: calc(8rem/16);
        padding-right: calc(8rem/16);
        font-size: calc(14rem/16);
    }
    @media screen and (max-width: 1199px) and (min-width: 992px) {
        padding: calc(10rem/16) calc(15rem/16);
    }
    @media screen and (max-width: 991px) {
        height: calc(50rem/16);
        width: calc(50rem/16);
        border-radius:0;
        padding: calc(5rem/16);
    }
}
.navbar-expand-lg .main-nav__nav .main-nav__nav-link {
    @media screen and (max-width: 1199px) and (min-width: 992px) {
        padding-left: calc(8rem/16);
        padding-right: calc(8rem/16);
    }
}
.main-nav.is-nav-white .main-nav__nav-item--tickets .main-nav__nav-item-btn, .main-nav.main-nav--lp .main-nav__nav-item--tickets .main-nav__nav-item-btn {
    border-radius:0;
}
.main-nav--lp .main-nav__collapse {
    display: none !important;
    @media screen and (max-width: 992px) {
        display:flex !important;
    }
}
.main-nav__nav-item--tickets .main-nav__nav-item-btn .icon {
    font-size: calc(40rem/16);
    margin-left: calc(10rem/16);
    margin-right: calc(10rem/16);
    position: absolute;
    left: calc(10rem/16);
    @media screen and (max-width: 1400px) and (min-width: 992px) {
        font-size: calc(25rem/16);
    }
    @media screen and (max-width: 1199px) {
        font-size: calc(27rem/16);
        margin-right:0;
        margin-left:0;
        position:relative;
        left:0;
    }
}
.is-nav-white .main-nav__nav-item--tickets .main-nav__nav-item-btn .icon {
    @media screen and (max-width: 1400px) and (min-width: 1200px) {
        top:calc(10rem/16);
        left:0;
        right:0;
        position:absolute;
    }
}

@media screen and (max-width: 991px) {
    .main-nav {
        padding:0;
    }
    .main-nav__collapse {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        max-width: 100vw;
        background: #fff;
        transform: translateX(100%);
        transition: transform .12s ease-out,visibility .001ms .12s;
        z-index: 101;
        visibility: hidden;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        color: var(--color-text-default);
        text-transform: uppercase;
        padding-top: calc(50rem/16);

    }
    .main-nav__collapse .main-nav__nav-link {
        font-size: calc(26rem/16);
        line-height: calc(33/26);
        text-align:center;
        padding-top: calc(15rem/16);
        padding-bottom: calc(15rem/16);
    }
    .main-nav.is-open .main-nav__collapse {
        transform: translateX(0);
        visibility: visible;
        transition: transform 120ms ease-out;
    }
    .main-nav__nav-item--booking {
        display:block;
    }
    .main-nav__nav-item--only-icon {
        padding-left: calc(10rem/16);
        padding-right: calc(10rem/16);

    }
    .main-nav__nav-item--img {
        max-width: calc(50rem/16);
        height: auto;
        max-height: calc(50rem/16);
        padding: 0 calc(5rem/16) !important;
    }
}

.main-nav--lp .main-nav__nav-wrapper--right {
    height:100%;
}
.main-nav--lp .main-nav__nav-item--booking {
    height:100%;
    max-width: calc(140rem/16);
    display:block;
}
.main-nav__nav-item.active {
    @media screen and (max-width: 767px) {
        color: var(--color-primary);
    }
}
.main-nav__nav-item.active:before {
    @media screen and (min-width: 768px) {
        width: 87%;
        background-color:#fff;
        border-radius: 2px;
    }
}
.main-nav__sub-nav__item.active {
    color: var(--color-primary-dark);
}