.event-teaser__embed:before {
    padding-top: calc( 27 / 33 * 100%);
}
.event-teaser {
    box-shadow: 0 calc(20rem/16) calc(40rem/16) 0 rgba(35,59,72,0.1);
}
.event-teaser__title {
    font-family: var(--font-default-bold);
    margin-top: calc(5rem/16);
    margin-bottom: calc(10rem/16);
    font-size: calc(20rem/16);
}
.event-teaser__date {
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    color: var(--color-primary);
}
.event-teaser__body {
    padding: calc(10rem/16) calc(30rem/16) calc(20rem/16);
}
.event-teaser__img {
    transition: transform 0.3s ease;
}
.event-teaser:hover .event-teaser__img{
    transform: scale(1.1);
}
.event-teaser__content {
    font-size: calc(16rem/16);
    line-height: calc(19rem/16);
}