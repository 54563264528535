.countdown__counter-value {
    font-size:calc(80rem/16);
    font-family:var(--font-default-bold);
    line-height:1;
    text-align:center;
    color:#fff;
    text-shadow: 0 0.125rem 0.5625rem rgba(0, 0, 0, 0.6);
    @media screen and (max-width: 767px) {
        font-size:calc(35rem/16);
    }
}
.countdown__counter-label {
    font-size:calc(28rem/16);
    font-family:var(--font-default-bold);
    line-height:1;
    color:var(--color-primary);
    text-align:center;
    margin-top: calc(20rem/16);
    text-transform:uppercase;
    @media screen and (max-width: 767px) {
        font-size:calc(12rem/16);
        margin-top: calc(10rem/16);
    }
}
.countdown__counter-item {
    padding: calc(20rem/16);
    background-color: var(--color-text-default);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 0.125rem 0.5625rem rgba(0,0,0,.5);
    @media screen and (min-width: 768px) {
        width: calc(200rem/16);
    }
}