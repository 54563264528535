.embed-responsive-3by2:before {
    padding-top: calc( 2 / 3 * 100%);
}

.card.card--package {
    border: none;
    border-radius: 0;
    box-shadow: 0 calc(20rem/16) calc(40rem/16) 0 rgba(35,59,72,0.1);
    padding: 0;
    background-color: transparent;
}
.card.card--package .card-body {
    padding: 0 0 calc(20rem/16);
    background-color: #ffffff;
}
.card.card--package .card-body > div:not(.embed-responsive) {
    padding: 0 calc(20rem/16);
}
.card.card--package .card-body h4 {
    font-size: calc(20rem/16);
    padding: 0 calc(20rem/16);
}

.card.card--package .card-body ul {
    list-style-type: none;
    padding-left: 0;
    padding-top: calc(10rem/16);
}
.card.card--package .card-body .text-primary {
    color: #000000 !important;
}

.pimcore_area_demi-destinationpackage-list .loading-overlay-container {
    overflow: visible;
}
.pimcore_area_demi-destinationpackage-list .col-lg-4 {
    margin-top: calc(20rem/16);
}
.demi-destinationpackage__filterbox-btn {
    @media screen and (max-width: 767px) {
        margin-top: calc(10rem/16);
    }
}

@media screen and (min-width: 1200px) {
    .card.card--package:hover {
        cursor: pointer;
    }
    .card.card--package img {
        transition: transform 0.4s ease-in-out;
    }
    .card.card--package:hover img {
        transform: scale(1.05);
    }
    .card.card--package:hover .card-body .text-primary {
        color: var(--color-primary) !important;
    }
}