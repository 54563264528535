.package-request__icon {
    font-size: calc(25rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
    }
}
.package-request__list-row:nth-of-type(even) {
    background-color: var(--color-primary-light);
}
.package-request__list {
    max-width: calc(1000rem/16);
    width: 100%;
    margin: 0 auto;
}
.package-request__date {
    font-size: calc(20rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}