.big-img-teaser {
    position: relative;
    cursor:pointer;
    box-shadow: 0 calc(20rem/16) calc(20rem/16) 0 rgba(35,59,72,0.3);
    @media screen and (max-width: 768px) {
        margin-bottom: calc(4rem/16);
    }
}
.big-img-teaser__title{
    font-size: calc(50rem/16);
    line-height:1;
    font-family: var(--font-decorative);
    text-shadow: 0 2px 30px rgba(0,0,0,0.7);
    @media screen and (max-width: 1199px) {
        font-size: calc(30rem/16);
    }
}
.big-img-teaser__subtitle {
    font-size: calc(18rem/16);
    text-shadow: 0 2px 20px rgba(0,0,0,0.5);
    font-family: var(--font-default);
    text-transform:uppercase;
}
.big-img-teaser__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    color:#fff;
    text-shadow: 0 2px 30px 0 rgba(0,0,0,0.7);
    padding: calc(30rem/16) calc(35rem/16);
    text-align:center;
    @media screen and (max-width: 1400px) {
        padding: calc(10rem/16);
    }
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16) calc(10rem/16);
    }
}
.big-img-teaser__body:before {
    top:0;
    position: absolute;
    content:'';
    left:0;
    width:100%;
    height:100%;
    z-index: -1;
    opacity:0.9;
    transition: opacity 0.3s ease;
    background: linear-gradient(180deg, rgba(47,66,80,0) 0%, rgba(47, 66, 80, 1) 100%);
}
.big-img-teaser:hover .big-img-teaser__body:before {
    opacity:0.5;
}
.big-img-teaser__icon {
    font-size: calc(40rem/16);
    margin-bottom: calc(10rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(30rem/16);
    }
}
.big-img-teaser--transformed {
    @media screen and (min-width: 768px) {
        margin-top: calc(80rem/16);
    }
}
.big-img-teaser__content {
    @media screen and (max-width: 1199px) {
        line-height:1.2;
        font-size: calc(16rem/16);
    }
    @media screen and (max-width: 767px) {
        display:none;
    }
}
.big-img-teaser--facts .big-img-teaser__icon {
    font-size: calc(40rem/16);
}
.big-img-teaser--facts .big-img-teaser__title {
    font-size: calc(38rem/16);
    @media screen and (max-width: 1400px) {
        font-size: calc(26rem/16);
    }
}
.big-img-teaser--facts .big-img-teaser__content {
    @media screen and (max-width: 1400px) and (min-width: 1200px) {
        line-height:1.1;
        font-size: calc(14rem/16);
    }
    @media screen and (max-width: 767px) {
        display:block;
    }
}