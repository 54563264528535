.blogger-table__table {
    color: var(--color-text-default);
}
.blogger-table__table thead {
    background-color: var(--color-primary);
    color:#fff;
}
.blogger-table__table thead th {
    padding: calc(19rem/16) calc(24rem/16);
}
.blogger-table__table tbody tr {
    border-bottom: 1px solid #c8c8c8;
}
.blogger-table__table tbody td {
    vertical-align: middle;
}
.blogger-table__user {
    width: calc(65rem/16);
    border-radius: 50%;
    box-shadow: 0 13px 18px 0 rgba(51,51,51,0.2);
    border: calc(5rem/16) solid #fff;
}