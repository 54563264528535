.blog-img-teaser__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 2;
    color:#fff;
    padding: calc(18rem/16) calc(24rem/16);
    text-shadow: 0 calc(2rem/16) calc(9rem/16) rgba(0,0,0,0.5);
    font-size: calc(15rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(5rem/16);
    }
}
.blog-img-teaser__author {
    max-width: calc(37rem/16);
}
.blog-img-teaser__author-wrapper {
    width: calc(37rem/16);
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 calc(13rem/16) calc(18rem/16) 0 rgba(51,51,51,0.2);
    @media screen and (max-width: 767px) {
        width: calc(25rem/16);
    }
}
.blog-img-teaser__author-name {
    font-family: var(--font-default-bold);
    line-height:1;
}
.blog-img-teaser__date {
    font-size: calc(13rem/16);
}
.blog-img-teaser__category {
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}
.blog-img-teaser__title {
    font-family: var(--font-default-bold);
    font-size: calc(22rem/16);
    line-height:1;
    @media screen and (max-width: 767px) {
        font-size: 1rem;
    }
}

@media screen and (min-width: 768px) {
    .blog-img-teaser__media {
        transition: transform 0.3s ease-in-out;
    }
    .blog-img-teaser:hover .blog-img-teaser__media {
        transform: scale(1.05);
    }
}