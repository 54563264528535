:root {
    --hero-space-bottom: calc(80rem/16);
    --hero-space-bottom-xs: calc(20rem/16);
}
.hero-blog-slider__wrapper {
    position: relative;
}
.hero-blog-slider__item:before {
    content:'';
    position: absolute;
    height: 60%;
    bottom:0;
    width: 100%;
    background: linear-gradient(180deg, rgba(47,66,80,0) 0%, #2F4250 100%);
    opacity:0.6;
}
.hero-blog-slider__item:after {
    content:'';
    position: absolute;
    height: 100%;
    left:0;
    width: 60%;
    background: linear-gradient(270deg, rgba(47,66,80,0) 0%, #2F4250 100%);
    opacity:0.6;
    top:0;
    z-index: -1;
}
.hero-blog-slider__item {
    height:  calc(100vh - var(--hero-space-bottom));
    min-height: calc(720rem/16);
    z-index: 2;
    position: relative;
    background-size:cover;
    @media screen and (max-width: 767px) and (orientation: landscape) {
        height: 150vh;
        min-height:auto;
    }
    @media screen and (max-width: 767px) and (orientation: portrait) {
        height: 70vh;
        min-height:auto;
    }
}
.hero-blog-slider__item-content {
    display: flex;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-end;
    padding-bottom: calc(50rem/16);
    color:#fff;
    @media screen and (max-width: 767px) {
        padding-bottom: calc(20rem/16);
    }
}
.hero-blog-slider--has-thumbs .hero-blog-slider__item-content {
    padding-bottom: calc(200rem/16);
    @media screen and (max-width: 767px) {
        padding-bottom: calc(20rem/16);
    }
}
.hero-blog-slider__headline {
    font-size: calc(20rem/16);
    text-shadow: 0 calc(2rem/16) calc(30rem/16) 0 rgba(0,0,0,0.5);
    line-height: calc(61/55);
    font-family: var(--font-default-bold);
    margin-bottom: calc(18rem/16);
    text-transform: uppercase;
    @media screen and (min-width: 768px) {
        font-size: calc(48rem/16);
    }
    @media screen and (min-width: 1440px) {
        font-size: calc(55rem/16);
    }
}
.hero-blog-slider__description {
    margin-bottom: calc(18rem/16);
    @media screen and (max-width: 767px) {
        display:none;
    }
}
.hero-blog-slider__author-block {
    margin-bottom: calc(20rem/16);
    align-items:center;
    text-shadow: 0 calc(2rem/16) calc(30rem/16) rgba(0,0,0,0.5);
}
.hero-blog-slider__author-name {
    font-family: var(--font-default-bold);
    line-height:1;
}
.hero-blog-slider__author-media {
    border-radius: 50%;
    box-shadow: 0 calc(13rem/16) calc(18rem/16) 0 rgba(51,51,51,0.2);
    border: calc(4rem/16) solid #FFFFFF;
    width: calc(64rem/16);
}
.hero-blog-slider__nav-wrapper {
    height: calc(80rem/16);
    position: absolute;
    bottom:0;
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 2;
}
.hero-blog-slider__nav {
    border-top: calc(1rem/16) solid #fff;
    height:100%;
    color:#fff;
}
.hero-blog-slider__nav-item {
    width: auto;
    height: calc(80rem/16);
}
.slick-slide .hero-blog-slider__nav-item-content:before {
    content:'';
    position: absolute;
    height: 0;
    background-color:#fff;
    top:0;
    width: 100%;
    left:0;
    opacity:0;
    transition: opacity 0.3s ease, height 0.3s ease-in-out;
}
.slick-slide.slick-current .hero-blog-slider__nav-item-content:before {
    opacity:1;
    height: calc(8rem/16);

}
.hero-blog-slider__nav-item-content {
    position: relative;
    display:flex;
    align-items:center;
    text-transform:uppercase;
    height:100%;
    margin-left: calc(30rem/16);
    margin-right: calc(30rem/16);
    cursor:pointer;
    transition: transform 0.2s ease-out;
}
.hero-blog-slider__nav-item-content:hover {
    transform: translateY(calc(-3rem/16));
}
.hero-blog-slider__nav .slick-slide:first-of-type .hero-blog-slider__nav-item-content{
    margin-left:0;
}
.hero-blog-slider__arrow-wrapper {
    position: absolute;
    bottom: calc(50rem/16);
    width: 100%;
    left:0;
    right: 0;
    pointer-events:none;
    @media screen and (max-width: 767px) {
        bottom: 1.25rem;
    }
}
.hero-blog-slider--has-thumbs .hero-blog-slider__arrow-wrapper {
    bottom: calc(140rem/16);
    @media screen and (max-width: 767px) {
        bottom: 1.25rem;
    }
}
.hero-blog-slider__arrow {
    position: relative;
}
.hero-blog-slider__arrow .icon {
    position: absolute;
    top: 50%;
    left: 50%;
}
.hero-blog-slider__arrow.arrow-next .icon {
    transform: translate(-50%, -50%) rotate(-90deg);
}
.hero-blog-slider__arrow.arrow-prev .icon {
    transform: translate(-50%, -50%) rotate(90deg);
}
@media screen and (min-width: 768px) {
    .hero-blog-slider__animated-item {
        opacity:0;
        transform: translateX(calc(200rem/16));
    }
    .hero-blog-slider__animated-item:nth-child(1) {
        transition: opacity 0.6s ease-out 0.5s, transform 0.8s cubic-bezier(0.4, 0, 0.3, 1) 0.5s;
    }
    .hero-blog-slider__animated-item:nth-child(2) {
        transition: opacity 0.6s ease-out 0.6s, transform 0.8s cubic-bezier(0.4, 0, 0.3, 1) 0.6s;
    }
    .hero-blog-slider__animated-item:nth-child(3) {
        transition: opacity 0.6s ease-out 0.7s, transform 0.8s cubic-bezier(0.4, 0, 0.3, 1) 0.7s;
    }
    .hero-blog-slider__animated-item:nth-child(4) {
        transition: opacity 0.6s ease-out 0.8s, transform 0.8s cubic-bezier(0.4, 0, 0.3, 1) 0.8s;
    }
    .slick-current.slick-active .hero-blog-slider__animated-item {
        transform: translateX(0rem);
        opacity:1;
    }
}
