.nested-fieldset__legend {
    color: var(--color-primary);
    font-size: calc(24rem/16);
    line-height: 1;
    padding-top: calc(5rem/16);
    padding-bottom: calc(5rem/16);
}
.nested-fieldset__legend .nested-fieldset__checkbox {
    padding-left: calc(40rem/16);
    display: flex;
}
.nested-fieldset__legend .nested-fieldset__checkbox .custom-checkbox__box {
    width: calc(25rem/16);
    height: calc(25rem/16);
    margin-left: calc(5rem/16);
    margin-top:0;
}
.nested-fieldset__body {
    padding: calc(10rem/16) calc(20rem/16);
    margin-left: calc(5rem/16);
    border-left: calc(1rem/16) solid var(--color-primary);
}
.nested-fieldset__checkbox .custom-checkbox__text {
    line-height:1;
}
@media screen and (max-width: 767px) {
    .nested-fieldset__checkbox {
        font-size: calc(14rem/16);
    }
    .nested-fieldset__checkbox .custom-checkbox__text {
        line-height: 1.4;
    }
}