
/* loading skier */
.loading-spinner {
    position: absolute;
    top: calc(-20rem/16);
    margin-left: calc(-40rem/16);
    left: 50%;
    animation: skiing .4s linear infinite;
}
.loading-spinner .skier .icon {
    font-size: calc(60rem/16);
    color: var(--color-primary);
}
.loading-spinner > span {
    height: calc(15rem/16);
    width: calc(35rem/16);
    background: transparent;
    position: absolute;
    top: calc(25rem/16);
    left: calc(-30rem/16);
    transform: rotate(23deg);
    border-radius: calc(2rem/16) calc(10rem/16) calc(1rem/16) 0;
}
.loading-spinner > .loading-spinner__item > .loading-spinner__snow:nth-child(1),
.loading-spinner > .loading-spinner__item > .loading-spinner__snow:nth-child(2),
.loading-spinner > .loading-spinner__item > .loading-spinner__snow:nth-child(3),
.loading-spinner > .loading-spinner__item > .loading-spinner__snow:nth-child(4) {
    width: calc(30rem/16);
    height: calc(1rem/16);
    background: var(--color-primary);
    position: absolute;
    animation: snowDash1 .2s linear infinite;
}

.loading-spinner > .loading-spinner__item > .loading-spinner__snow:nth-child(2) {
    top: calc(3rem/16);
    animation: snowDash2 .4s linear infinite;
}

.loading-spinner > .loading-spinner__item > .loading-spinner__snow:nth-child(3) {
    top: calc(1rem/16);
    animation: snowDash3 .4s linear infinite;
    animation-delay: -1s;
}

.loading-spinner > .loading-spinner__item > .loading-spinner__snow:nth-child(4) {
    top: calc(4rem/16);
    animation: snowDash4 1s linear infinite;
    animation-delay: -1s;
}

@keyframes snowDash1 {
    0% {
        left: 0;
    }
    100% {
        left: calc(-80rem/16);
        opacity: 0;
    }
}
@keyframes snowDash2 {
    0% {
        left: 0;
    }
    100% {
        left: calc(-100rem/16);
        opacity: 0;
    }
}
@keyframes snowDash3 {
    0% {
        left: 0;
    }
    100% {
        left: calc(-50rem/16);
        opacity: 0;
    }
}
@keyframes snowDash4 {
    0% {
        left: 0;
    }
    100% {
        left: calc(-150rem/16);
        opacity: 0;
    }
}
@keyframes skiing {
    0% {
        transform: translate(2px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -3px) rotate(-1deg);
    }
    20% {
        transform: translate(-2px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 3px) rotate(-1deg);
    }
    60% {
        transform: translate(-1px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-2px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(2px, 1px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}