@media screen and (max-width: 991px) {
    .main-nav__toggler {
        position: relative;
        background-color: #fff;
        width: calc(50rem/16);
        height: calc(50rem/16);
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transition: .5s ease-in-out;
        z-index: 999;
    }
    .navbar-toggler.main-nav__toggler:focus {
        outline: none;
    }
    .main-nav__toggler-bar {
        display: block;
        height: calc(3rem/16);
        width: calc(31rem/16);
        background-color: var(--color-text-default);
        padding: 0;
        position: absolute;
        opacity: 1;
        left: calc(8rem/16);
        transform: rotate(0deg);
        transition: .25s ease-in-out;
        transform-origin: left center;
    }
    .main-nav__toggler .main-nav__toggler-bar:first-child{
        top: calc(14rem/16);
    }
    .main-nav__toggler .main-nav__toggler-bar:nth-child(2) {
        top: calc(22rem/16);
    }
    .main-nav__toggler .main-nav__toggler-bar:last-child{
        top: calc(30rem/16);
    }
    .main-nav.is-open .main-nav__toggler .main-nav__toggler-bar:first-child{
        transform: rotate(45deg);
        top: calc(10rem/16);
        left: calc(8rem/16);
    }
    .main-nav.is-open .main-nav__toggler .main-nav__toggler-bar:nth-child(2) {
        width: 0;
        opacity: 0;
    }
    .main-nav.is-open .main-nav__toggler .main-nav__toggler-bar:last-child{
        transform: rotate(-45deg);
        top: calc(32rem/16);
        left: calc(8rem/16);
    }
}